import { Card, Group, Stack, Text } from "@mantine/core";
import { Money } from "@zozia/money";
import { Price, cropImageTo } from "@zozia/ui";
import * as React from "react";

import { AutoAlignImage } from "../../../components/AutoAlignImage";

const cropImageToDesiredSize = cropImageTo(50);

export const OrderedItem = ({ item }) => {
  return (
    <Card bg="#FFFFFF" radius="lg" padding="xs">
      <Group wrap="nowrap" align="start">
        <AutoAlignImage
          className="rounded-xl"
          src={
            item.offer.product.primaryImage?.url
              ? cropImageToDesiredSize(item.offer.product.primaryImage?.url)
              : null
          }
          style={{ width: 50, height: 50 }}
        />
        <Stack className="-mt-1 w-full" gap={4}>
          <Group justify="space-between">
            <Text size="xs" fw="600">
              {item.offer.product.name}
            </Text>
            <Price
              size="sm"
              as={Text}
              value={Money.fromFractionlessAmount(
                item.offer.price,
                (item.offer.currency || "PLN").toUpperCase(),
              ).toNumber()}
            />
          </Group>
          <Text size="xs">
            <Price
              value={Money.fromFactionlessAndQuantity(
                item.offer.price,
                item.offer.quantity,
                (item.offer.currency || "PLN").toUpperCase(),
              ).toNumber()}
            />
          </Text>
          <Text size="xs" c="dimmed">
            {item.offer.quantity} szt.
          </Text>
        </Stack>
      </Group>
    </Card>
  );
};
