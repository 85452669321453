import { useMutation, useQuery, useInfiniteQuery, UseMutationOptions, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useFetchData } from '../../lib/capacitor-fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  File: { input: any; output: any; }
  Json: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AcceptLocationRequestResponse = {
  __typename?: 'AcceptLocationRequestResponse';
  error?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type Ad = {
  __typename?: 'Ad';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  publisher?: Maybe<User>;
  story?: Maybe<Story>;
  type: AdType;
  updatedAt: Scalars['String']['output'];
};

export type AdType =
  | 'Story';

export type AddCartItemInput = {
  locationId: Scalars['ID']['input'];
  offerId: Scalars['ID']['input'];
};

export type AddCartItemResponse = {
  __typename?: 'AddCartItemResponse';
  cartItemId?: Maybe<Scalars['ID']['output']>;
};

export type AddTaxInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  rate: Scalars['Int']['input'];
};

export type AddressType =
  | 'BillAddress'
  | 'DeliveryAddress'
  | 'InvoiceAddress';

export type AdminAcceptProductSuggestionInput = {
  acceptedSuggestions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  data?: InputMaybe<Scalars['Json']['input']>;
  productRequestUpdateId: Scalars['ID']['input'];
  rejectedSuggestions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type AdminAcceptProductSuggestionResult = {
  __typename?: 'AdminAcceptProductSuggestionResult';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type AdminBrand = BaseBrand & {
  __typename?: 'AdminBrand';
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['Json']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  names?: Maybe<Array<Maybe<BrandName>>>;
  productCount?: Maybe<Scalars['Int']['output']>;
  products?: Maybe<Array<Maybe<Product>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type AdminBusinessOwner = {
  __typename?: 'AdminBusinessOwner';
  avatar?: Maybe<Scalars['String']['output']>;
  businessId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['ID']['output']>;
};

export type AdminBusinessOwnersInput = {
  searchName?: InputMaybe<Scalars['String']['input']>;
};

export type AdminContextualDiscovery = {
  __typename?: 'AdminContextualDiscovery';
  active?: Maybe<Scalars['Boolean']['output']>;
  categories?: Maybe<Array<Maybe<AdminContextualDiscoveryCategory>>>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AdminContextualDiscoveryCategory = {
  __typename?: 'AdminContextualDiscoveryCategory';
  category?: Maybe<LocationCategory>;
  categoryId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  locations?: Maybe<Array<Maybe<Location>>>;
};

export type AdminContextualDiscoveryCategoryLocation = {
  __typename?: 'AdminContextualDiscoveryCategoryLocation';
  contextualDiscoveryCategoryId: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
};

export type AdminContextualDiscoveryInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};

export type AdminCreateMerchantInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  taxId: Scalars['String']['input'];
};

export type AdminDeleteProductInput = {
  productId: Scalars['ID']['input'];
};

export type AdminDisconnectContextualDiscoveryCategoryResult = {
  __typename?: 'AdminDisconnectContextualDiscoveryCategoryResult';
  id: Scalars['ID']['output'];
};

export type AdminFindProductDuplicateInput = {
  field: Scalars['String']['input'];
};

export type AdminLocation = {
  __typename?: 'AdminLocation';
  business?: Maybe<Business>;
  location: Location;
};

export type AdminLocationsSearchInput = {
  contextualDiscoveryId: Scalars['ID']['input'];
};

export type AdminPaginatedProductsToVerify = {
  __typename?: 'AdminPaginatedProductsToVerify';
  cursor?: Maybe<Scalars['ID']['output']>;
  data?: Maybe<Array<Maybe<AdminProductToVerify>>>;
};

export type AdminProductToVerify = {
  __typename?: 'AdminProductToVerify';
  createdAt?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['Json']['output']>;
  ean?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Product>;
  requester?: Maybe<User>;
  status?: Maybe<Scalars['String']['output']>;
  suggestions?: Maybe<Array<Maybe<ProductSuggestion>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type AdminProductsToVerifyInput = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  filters?: InputMaybe<Array<InputMaybe<AdminProductsToVerifyInputFilter>>>;
};

export type AdminProductsToVerifyInputFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpsertBrandInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  meta?: InputMaybe<Scalars['Json']['input']>;
  name: Scalars['String']['input'];
};

export type AdminUpsertBrandNameInput = {
  brandNameId?: InputMaybe<Scalars['ID']['input']>;
  manufacturerId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpsertContextualDiscoveryCategoryInput = {
  categoryId: Scalars['ID']['input'];
  contextualDiscoveryCategoryId?: InputMaybe<Scalars['ID']['input']>;
  contextualDiscoveryId: Scalars['ID']['input'];
};

export type AdminUpsertContextualDiscoveryCategoryLocationInput = {
  contextualDiscoveryCategoryId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
};

export type AdminUpsertEmailInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  template: Scalars['String']['input'];
};

export type AdminUpsertLocationCategoryInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type AdminUpsertLocationOwnerInput = {
  businessId?: InputMaybe<Scalars['ID']['input']>;
  locationId: Scalars['ID']['input'];
};

export type AdminUpsertLocationOwnerResult = {
  __typename?: 'AdminUpsertLocationOwnerResult';
  ok: Scalars['Boolean']['output'];
};

export type AdminUser = {
  __typename?: 'AdminUser';
  avatar?: Maybe<Scalars['String']['output']>;
  businessId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type AdsInput = {
  locationId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type BaseBrand = {
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['Json']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  names?: Maybe<Array<Maybe<BrandName>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type Brand = BaseBrand & {
  __typename?: 'Brand';
  counts?: Maybe<BrandNestedCount>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['Json']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  names?: Maybe<Array<Maybe<BrandName>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type BrandInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BrandInputFrom =
  | 'BIR'
  | 'Form'
  | 'GS1';

export type BrandName = {
  __typename?: 'BrandName';
  id: Scalars['ID']['output'];
  manufacturerId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type BrandNestedCount = {
  __typename?: 'BrandNestedCount';
  products?: Maybe<Scalars['Int']['output']>;
};

export type BrandsFromCategoryInput = {
  categoryId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
};

export type BrandsInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};

export type Business = {
  __typename?: 'Business';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  employees?: Maybe<Array<Maybe<Employee>>>;
  id?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['Json']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<User>;
  paymentProfiles?: Maybe<Array<Maybe<PaymentProfile>>>;
  paymentProviderAccount?: Maybe<Scalars['Json']['output']>;
  regon?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BusinessSetupProcess = {
  __typename?: 'BusinessSetupProcess';
  currentStep: Scalars['Int']['output'];
  isCompleted: Scalars['Boolean']['output'];
  steps: Array<BusinessSetupStep>;
};

export type BusinessSetupStep = {
  __typename?: 'BusinessSetupStep';
  data: Scalars['Json']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isCompleted: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  step: Scalars['Int']['output'];
};

export type CartItem = {
  __typename?: 'CartItem';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  offer: Offer;
  offerId: Scalars['ID']['output'];
  price: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  replacement?: Maybe<Offer>;
  replacementId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type CategoriesFromParentInput = {
  locationId: Scalars['ID']['input'];
  parentCategoryId: Scalars['ID']['input'];
};

export type CategoriesFromProductsResult = {
  __typename?: 'CategoriesFromProductsResult';
  id: Scalars['ID']['output'];
  meta?: Maybe<CategoryMeta>;
  name: Scalars['String']['output'];
  productsCount: Scalars['Int']['output'];
};

export type Category = {
  __typename?: 'Category';
  children?: Maybe<Array<Maybe<Category>>>;
  counts?: Maybe<CategoryNestedCount>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['Json']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Category>;
  parentId?: Maybe<Scalars['ID']['output']>;
  taxId?: Maybe<Scalars['ID']['output']>;
  tree?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type CategoryInput = {
  meta?: InputMaybe<Scalars['Json']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type CategoryMeta = {
  __typename?: 'CategoryMeta';
  icon?: Maybe<Scalars['String']['output']>;
};

export type CategoryNestedCount = {
  __typename?: 'CategoryNestedCount';
  products?: Maybe<Scalars['Int']['output']>;
};

export type CategoryWithProducts = {
  __typename?: 'CategoryWithProducts';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<Category>;
  parentId?: Maybe<Scalars['ID']['output']>;
  products?: Maybe<Array<Maybe<Product>>>;
};

export type City = {
  __typename?: 'City';
  boundingbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  lat: Scalars['String']['output'];
  lon: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CityListInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyInfo = {
  __typename?: 'CompanyInfo';
  city?: Maybe<Scalars['String']['output']>;
  community?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  flaatNo?: Maybe<Scalars['String']['output']>;
  houseNo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  regon?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  voivodeship?: Maybe<Scalars['String']['output']>;
};

export type CompleteCartProcessData = {
  __typename?: 'CompleteCartProcessData';
  orderId: Scalars['ID']['output'];
};

export type CompleteCartProcessInput = {
  cartId: Scalars['ID']['input'];
  deliveryAddressId: Scalars['ID']['input'];
};

export type CompleteCartProcessResponse = {
  __typename?: 'CompleteCartProcessResponse';
  data?: Maybe<CompleteCartProcessData>;
};

export type CountProductsInput = {
  filters?: InputMaybe<Array<InputMaybe<ProductFilter>>>;
};

export type Counts = {
  __typename?: 'Counts';
  location?: Maybe<CountsResult>;
  offerts?: Maybe<CountsResult>;
  product?: Maybe<CountsResult>;
  productRequestUpdate?: Maybe<CountsResult>;
  shopping?: Maybe<CountsResult>;
  shoppingItem?: Maybe<CountsResult>;
  user?: Maybe<CountsResult>;
};

export type CountsResult = {
  __typename?: 'CountsResult';
  count: Scalars['Int']['output'];
  diff: Array<Maybe<MonthCountResult>>;
};

export type CreateBrandInput = {
  inputFrom?: InputMaybe<BrandInputFrom>;
  meta?: InputMaybe<Scalars['Json']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLocationsFromBoundaryInput = {
  boundary?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>>>;
  location: LatLong;
  radius: Scalars['Int']['input'];
};

export type CreatePaymentIntentInput = {
  currency?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  shoppingCartId: Scalars['ID']['input'];
};

export type CreateReelInput = {
  source: Scalars['String']['input'];
  storyId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export type DecreaseCartItemInput = {
  cartItemId: Scalars['ID']['input'];
};

export type DeleteMerchantWarehousePackItemResult = {
  __typename?: 'DeleteMerchantWarehousePackItemResult';
  id: Scalars['ID']['output'];
};

export type DeleteProductImageInput = {
  imageId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
};

export type DeleteProductImageResult = {
  __typename?: 'DeleteProductImageResult';
  deletedImageId?: Maybe<Scalars['ID']['output']>;
};

export type DeleteProductResult = {
  __typename?: 'DeleteProductResult';
  id: Scalars['ID']['output'];
};

export type DeleteWarehousePackItemImageInput = {
  imageId: Scalars['ID']['input'];
  warehousePackItemId: Scalars['ID']['input'];
};

export type DeliveryOption = {
  __typename?: 'DeliveryOption';
  currency: Scalars['String']['output'];
  deliveryHours: Scalars['Json']['output'];
  deliveryPrice: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  minOrderAmount?: Maybe<Scalars['Int']['output']>;
  radius?: Maybe<Scalars['Int']['output']>;
  type: DeliveryType;
};

export type DeliveryOptionCopy = {
  __typename?: 'DeliveryOptionCopy';
  deliveryPrice: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  radius?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};

export type DeliveryPackingOption = {
  __typename?: 'DeliveryPackingOption';
  createdAt?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locationId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  packingPrice: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type DeliveryPackingOptionCopy = {
  __typename?: 'DeliveryPackingOptionCopy';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locationId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  packingPrice: Scalars['Int']['output'];
};

export type DeliveryPricing = {
  __typename?: 'DeliveryPricing';
  deliveryPrice: Scalars['Int']['output'];
};

export type DeliveryPricingInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  locationId: Scalars['ID']['input'];
};

export type DeliveryType =
  | 'DeliveryByOwner'
  | 'Pickup';

export type Discovery = {
  __typename?: 'Discovery';
  categories?: Maybe<Array<Maybe<DiscoveryCategory>>>;
  id?: Maybe<Scalars['ID']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DiscoveryCategory = {
  __typename?: 'DiscoveryCategory';
  id: Scalars['ID']['output'];
  locations?: Maybe<Array<Maybe<Location>>>;
  name: Scalars['String']['output'];
};

export type DiscoveryCity = {
  __typename?: 'DiscoveryCity';
  distance?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type DiscoveryLocationInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type Email = {
  __typename?: 'Email';
  history?: Maybe<Array<Maybe<EmailHistory>>>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  template: Scalars['String']['output'];
};

export type EmailHistory = {
  __typename?: 'EmailHistory';
  changedBy: User;
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  prevSubject: Scalars['String']['output'];
  prevTemplate: Scalars['String']['output'];
};

export type Employee = {
  __typename?: 'Employee';
  employee: User;
  id: Scalars['ID']['output'];
  invite?: Maybe<EmployeeInvite>;
  roles?: Maybe<Array<Maybe<EmployeeRole>>>;
};

export type EmployeeInvite = {
  __typename?: 'EmployeeInvite';
  status?: Maybe<Scalars['String']['output']>;
};

export type EmployeeRole = {
  __typename?: 'EmployeeRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  rule?: Maybe<EmployeeRule>;
};

export type EmployeeRule = {
  __typename?: 'EmployeeRule';
  action?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  condition?: Maybe<Scalars['Json']['output']>;
  subject?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ExistingImageInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type FavouritesProductsInput = {
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
};

export type FindNearestPlaceForProductInput = {
  location: LatLong;
  productId: Scalars['ID']['input'];
};

export type Image = {
  __typename?: 'Image';
  blurhash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type IncreaseCartItemInput = {
  cartItemId: Scalars['ID']['input'];
};

export type LatLong = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type ListOffersByCategoryInput = {
  categoryId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
};

export type ListOffersInput = {
  location?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  productId?: InputMaybe<Scalars['String']['input']>;
  radius?: InputMaybe<Scalars['Int']['input']>;
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']['output']>;
  businessEmail?: Maybe<Scalars['String']['output']>;
  coverPhoto?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  flags?: Maybe<Scalars['Json']['output']>;
  hasOwner?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Image>>>;
  isFavourite?: Maybe<Scalars['Boolean']['output']>;
  isPendingInvite?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  openingHours?: Maybe<Scalars['Json']['output']>;
  placeId?: Maybe<Scalars['ID']['output']>;
  products?: Maybe<Array<Maybe<Product>>>;
  websites?: Maybe<Array<Maybe<Website>>>;
};


export type LocationImagesArgs = {
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LocationCategory = {
  __typename?: 'LocationCategory';
  distance?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type LocationInput = {
  address: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  placeId?: InputMaybe<Scalars['ID']['input']>;
};

export type LocationPaymentMethod = {
  __typename?: 'LocationPaymentMethod';
  deliveryOptionId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<LocationPaymentMethodType>;
};

export type LocationPaymentMethodType =
  | 'ApplePay'
  | 'Blik'
  | 'Card'
  | 'Cash'
  | 'GooglePay'
  | 'Online';

export type LocationRequest = {
  __typename?: 'LocationRequest';
  additionalInfo?: Maybe<Scalars['Json']['output']>;
  business?: Maybe<Business>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  location?: Maybe<Location>;
  status?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type LocationRequestInput = {
  business: Scalars['Json']['input'];
  locationId: Scalars['ID']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type LocationRequestsInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};

export type LocationsForProductInput = {
  location: LatLong;
  productId: Scalars['ID']['input'];
  radius: Scalars['Float']['input'];
};

export type LocationsInput = {
  boundary?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>>>;
  cursor?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location: LatLong;
  radius: Scalars['Int']['input'];
};

export type Merchant = {
  __typename?: 'Merchant';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type MerchantActiveCartsInput = {
  locationId: Scalars['ID']['input'];
};

export type MerchantAddDeliveryPackingOptionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  packingPrice: Scalars['Int']['input'];
};

export type MerchantAssignOffertToVariantInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  offerId: Scalars['ID']['input'];
  parentOfferId: Scalars['ID']['input'];
  variantId: Scalars['ID']['input'];
};

export type MerchantAssignProductsToLocationInput = {
  warehousePackId: Scalars['ID']['input'];
};

export type MerchantAssignProductsToLocationResult = {
  __typename?: 'MerchantAssignProductsToLocationResult';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type MerchantChangeOrderStatusInput = {
  estimatedDeliveryTime?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  status: OrderStatus;
};

export type MerchantCompletePaymentProfileInput = {
  name: Scalars['String']['input'];
};

export type MerchantConfirmAdditionalLocationOptionsInput = {
  businessSetupStepId: Scalars['ID']['input'];
};

export type MerchantConfirmBusinesProfileInput = {
  businessSetupStepId: Scalars['ID']['input'];
  taxId?: InputMaybe<Scalars['String']['input']>;
};

export type MerchantCreateLocationInput = {
  address: Scalars['String']['input'];
  businessEmail?: InputMaybe<Scalars['String']['input']>;
  businessSetupStepId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  openingHours: Scalars['String']['input'];
};

export type MerchantCreateOfferVariantInput = {
  offerId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export type MerchantCreatePaymentProviderAccountInput = {
  test?: InputMaybe<Scalars['String']['input']>;
};

export type MerchantDeleteCategoryShowcaseResponse = {
  __typename?: 'MerchantDeleteCategoryShowcaseResponse';
  success: Scalars['Boolean']['output'];
};

export type MerchantDeleteOfferInput = {
  offerId: Scalars['ID']['input'];
};

export type MerchantDelivery = {
  __typename?: 'MerchantDelivery';
  deliveryHours?: Maybe<Scalars['Json']['output']>;
  id: Scalars['ID']['output'];
  minOrderAmount?: Maybe<Scalars['Int']['output']>;
  options?: Maybe<Array<Maybe<MerchantDeliveryOption>>>;
};

export type MerchantDeliveryOption = {
  __typename?: 'MerchantDeliveryOption';
  description: Scalars['String']['output'];
  geojson?: Maybe<Scalars['Json']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type MerchantDeliveryOptionInput = {
  geom?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  type: Scalars['String']['input'];
};

export type MerchantDisconnectOffertFromVariantInput = {
  offerId: Scalars['ID']['input'];
  variantId: Scalars['ID']['input'];
};

export type MerchantDisconnectProductFromCategoryShowcaseInput = {
  productId: Scalars['ID']['input'];
};

export type MerchantDisconnectProductFromCategoryShowcaseResponse = {
  __typename?: 'MerchantDisconnectProductFromCategoryShowcaseResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type MerchantInviteEmployeeInput = {
  email: Scalars['String']['input'];
  locationId: Scalars['String']['input'];
};

export type MerchantLocation = {
  __typename?: 'MerchantLocation';
  address?: Maybe<Scalars['String']['output']>;
  employeePermissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  flags?: Maybe<Scalars['Json']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Image>>>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
};

export type MerchantLocationImageInput = {
  file: Scalars['File']['input'];
  size: Scalars['String']['input'];
};

export type MerchantOnboardingStatus = {
  __typename?: 'MerchantOnboardingStatus';
  currentStep: Scalars['Int']['output'];
};

export type MerchantOrdersInput = {
  locationId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<OrderStatus>;
};

export type MerchantTogglePriceHiddenResult = {
  __typename?: 'MerchantTogglePriceHiddenResult';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type MerchantUpdateDeliveryPackingOptionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  packingPrice?: InputMaybe<Scalars['Int']['input']>;
};

export type MerchantUpdateLocationInput = {
  businessEmail?: InputMaybe<Scalars['String']['input']>;
  coverImage?: InputMaybe<MerchantLocationImageInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['ID']['input'];
  logoImage?: InputMaybe<MerchantLocationImageInput>;
  openingHours?: InputMaybe<Scalars['String']['input']>;
  websites?: InputMaybe<Array<InputMaybe<WebsiteInput>>>;
};

export type MerchantUpdateOfferInput = {
  active: Scalars['Boolean']['input'];
  offerId: Scalars['ID']['input'];
  price: Scalars['Int']['input'];
};

export type MerchantUpdatePaymentProviderAccountInput = {
  test?: InputMaybe<Scalars['String']['input']>;
};

export type MerchantUpsertCategoryShowcaseInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
};

export type MerchantUpsertCategoryShowcaseResponse = {
  __typename?: 'MerchantUpsertCategoryShowcaseResponse';
  success: Scalars['Boolean']['output'];
};

export type MerchantUpsertDeliveryOptionInput = {
  active: Scalars['Boolean']['input'];
  currency: Scalars['String']['input'];
  deliveryOptionId?: InputMaybe<Scalars['ID']['input']>;
  deliveryOptionType: DeliveryType;
  deliveryPrice: Scalars['Int']['input'];
  locationId: Scalars['ID']['input'];
  radius?: InputMaybe<Scalars['Int']['input']>;
};

export type MerchantUpsertDeliveryPackingOptionInput = {
  active: Scalars['Boolean']['input'];
  deliveryPackingOptionId?: InputMaybe<Scalars['ID']['input']>;
  description: Scalars['String']['input'];
  locationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  packingPrice: Scalars['Int']['input'];
};

export type MerchantUpsertLocationInput = {
  address: Scalars['String']['input'];
  businessEmail?: InputMaybe<Scalars['String']['input']>;
  businessSetupStepId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  openingHours: Scalars['String']['input'];
};

export type MerchantUpsertPaymentMethodInput = {
  active: Scalars['Boolean']['input'];
  locationId: Scalars['ID']['input'];
  locationPaymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  paymentType: LocationPaymentMethodType;
};

export type MerchantUpsertWarehousePackItemInput = {
  currency?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  ean?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['File']['input']>>>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  meta: MerchantUpsertWarehousePackItemMetaInput;
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  taxRate: Scalars['Int']['input'];
  warehousePackId?: InputMaybe<Scalars['ID']['input']>;
  withoutEan: Scalars['Boolean']['input'];
};

export type MerchantUpsertWarehousePackItemMetaInput = {
  brandId: Scalars['ID']['input'];
  categoryId: Scalars['ID']['input'];
};

export type MerchanttoggleActiveShoppingResult = {
  __typename?: 'MerchanttoggleActiveShoppingResult';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type MonthCountResult = {
  __typename?: 'MonthCountResult';
  count: Scalars['BigInt']['output'];
  date?: Maybe<Scalars['DateTime']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptLocationRequest?: Maybe<AcceptLocationRequestResponse>;
  addCartItem?: Maybe<AddCartItemResponse>;
  addUserBillAddress: UserAddress;
  addUserInvoiceAddress: UserAddress;
  adminAcceptProductSuggestion?: Maybe<AdminAcceptProductSuggestionResult>;
  adminAddTax: Tax;
  adminCreateMerchant: Merchant;
  adminDeleteBrand?: Maybe<AdminBrand>;
  adminDeleteBrandName?: Maybe<BrandName>;
  adminDeleteEmail?: Maybe<Email>;
  adminDeleteProduct?: Maybe<Product>;
  adminDisconnectContextualDiscoveryCategory?: Maybe<AdminDisconnectContextualDiscoveryCategoryResult>;
  adminDisconnectContextualDiscoveryCategoryLocation?: Maybe<AdminContextualDiscoveryCategoryLocation>;
  adminRejectProductSuggestion?: Maybe<AdminAcceptProductSuggestionResult>;
  adminToggleContextualDiscoveryActive?: Maybe<Scalars['Boolean']['output']>;
  adminUpsertBrand?: Maybe<AdminBrand>;
  adminUpsertBrandName?: Maybe<BrandName>;
  adminUpsertContextualDiscoveryCategory?: Maybe<AdminContextualDiscoveryCategory>;
  adminUpsertContextualDiscoveryCategoryLocation?: Maybe<AdminContextualDiscoveryCategoryLocation>;
  adminUpsertEmail?: Maybe<Email>;
  adminUpsertLocationCategory: Scalars['ID']['output'];
  adminUpsertLocationOwner?: Maybe<AdminUpsertLocationOwnerResult>;
  completeCartProcess: CompleteCartProcessResponse;
  createBrand: Brand;
  createCategory?: Maybe<Category>;
  createLocation?: Maybe<Location>;
  createLocationRequest?: Maybe<LocationRequest>;
  createLocationsFromBoundary?: Maybe<Location>;
  createPaymentIntent?: Maybe<PaymentIntent>;
  createProduct?: Maybe<Product>;
  createReel: Reel;
  decreaseCartItem: Scalars['Boolean']['output'];
  deleteBrand: Brand;
  deleteCategory?: Maybe<Category>;
  deleteProduct: DeleteProductResult;
  deleteProductImage: DeleteProductImageResult;
  deleteWarehousePackItemImage: DeleteProductImageResult;
  employeeAcceptInvite: Scalars['String']['output'];
  increaseCartItem: Scalars['Boolean']['output'];
  merchantActivateAccount?: Maybe<Scalars['Boolean']['output']>;
  merchantAddDeliveryPackingOption?: Maybe<DeliveryPackingOption>;
  merchantAssignOffertToVariant: OfferVariant;
  merchantAssignProductsToLocation: MerchantAssignProductsToLocationResult;
  merchantChangeOrderStatus?: Maybe<Order>;
  merchantCompletePaymentProfile?: Maybe<MerchantOnboardingStatus>;
  merchantConfirmAdditionalLocationOptions?: Maybe<MerchantOnboardingStatus>;
  merchantConfirmBusinessProfile?: Maybe<MerchantOnboardingStatus>;
  merchantConfirmPhoneNumber?: Maybe<MerchantOnboardingStatus>;
  merchantCreateLocation?: Maybe<Location>;
  merchantCreateOfferVariant: OfferVariant;
  merchantCreatePaymentProviderAccount?: Maybe<Scalars['Json']['output']>;
  merchantCreateWarehousePack?: Maybe<WarehousePack>;
  merchantDeleteCategoryShowcase: MerchantDeleteCategoryShowcaseResponse;
  merchantDeleteDeliveryOption?: Maybe<Scalars['Boolean']['output']>;
  merchantDeleteDeliveryPackingOption?: Maybe<Scalars['Boolean']['output']>;
  merchantDeleteOffer?: Maybe<Scalars['Boolean']['output']>;
  merchantDeletePaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  merchantDeletePaymentProviderAccount?: Maybe<Scalars['Json']['output']>;
  merchantDeleteWarehousePack: Scalars['ID']['output'];
  merchantDeleteWarehousePackItem: DeleteMerchantWarehousePackItemResult;
  merchantDisconnectOffertFromVariant: OfferVariantToOffer;
  merchantDisconnectProductFromCategoryShowcase?: Maybe<MerchantDisconnectProductFromCategoryShowcaseResponse>;
  merchantInviteEmployee: Scalars['String']['output'];
  merchantMarkProductAsActive?: Maybe<Scalars['Int']['output']>;
  merchantMarkProductAsDeactivated?: Maybe<Scalars['Int']['output']>;
  merchantToggleActiveSale: MerchanttoggleActiveShoppingResult;
  merchantTogglePriceHidden: MerchantTogglePriceHiddenResult;
  merchantUpdateDeliveryPackingOption?: Maybe<DeliveryPackingOption>;
  merchantUpdateLocation?: Maybe<Location>;
  merchantUpdateOffer?: Maybe<Offer>;
  merchantUpdatePaymentProviderAccount?: Maybe<Scalars['Json']['output']>;
  merchantUpsertCategoryShowcase?: Maybe<MerchantUpsertCategoryShowcaseResponse>;
  merchantUpsertDeliveryOption?: Maybe<Scalars['Boolean']['output']>;
  merchantUpsertDeliveryPackingOption?: Maybe<Scalars['Boolean']['output']>;
  merchantUpsertLocation?: Maybe<Location>;
  merchantUpsertPaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  merchantUpsertWarehousePackItem: WarehouseProduct;
  notificationMarkAs?: Maybe<Notification>;
  rejectLocationRequest?: Maybe<RejectLocationRequestResponse>;
  removeCart: Scalars['Boolean']['output'];
  removeCartItem?: Maybe<Scalars['Boolean']['output']>;
  sendVerificationCode: Scalars['Boolean']['output'];
  setCartDeliveryAddress: UserAddressResponse;
  setCartGroupDeliveryOption: Scalars['Boolean']['output'];
  setCartGroupDeliveryPackingOption: Scalars['Boolean']['output'];
  setCartGroupPaymentMethod: Scalars['Boolean']['output'];
  setCartItemNote: Scalars['Boolean']['output'];
  setCartItemQuantity?: Maybe<SetCartItemQuantityResponse>;
  setCartItemReplacement: Scalars['Boolean']['output'];
  setPhoneNumber: Scalars['Boolean']['output'];
  toggleFavouriteLocation?: Maybe<Location>;
  tryToCreateProductFromOnline?: Maybe<Product>;
  updateBrand: UpdateBrandResult;
  updateCategory?: Maybe<Category>;
  updateProduct: UpdateProductResult;
  updateReel: Reel;
  upsertProduct: UpdateProductResult;
  upsertUserDeliveryAddress: UserAddressResponse;
  upsertWarehousePack?: Maybe<UpsertWarehousePackResult>;
};


export type MutationAcceptLocationRequestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAddCartItemArgs = {
  input: AddCartItemInput;
};


export type MutationAddUserBillAddressArgs = {
  input: UserBillAddressInput;
};


export type MutationAddUserInvoiceAddressArgs = {
  input: UserInvoiceAddressInput;
};


export type MutationAdminAcceptProductSuggestionArgs = {
  input?: InputMaybe<AdminAcceptProductSuggestionInput>;
};


export type MutationAdminAddTaxArgs = {
  input: AddTaxInput;
};


export type MutationAdminCreateMerchantArgs = {
  input: AdminCreateMerchantInput;
};


export type MutationAdminDeleteBrandArgs = {
  brandId: Scalars['ID']['input'];
};


export type MutationAdminDeleteBrandNameArgs = {
  brandNameId: Scalars['ID']['input'];
};


export type MutationAdminDeleteEmailArgs = {
  emailTemplateId: Scalars['String']['input'];
};


export type MutationAdminDeleteProductArgs = {
  input: AdminDeleteProductInput;
};


export type MutationAdminDisconnectContextualDiscoveryCategoryArgs = {
  contextualDiscoveryCategoryId: Scalars['ID']['input'];
};


export type MutationAdminDisconnectContextualDiscoveryCategoryLocationArgs = {
  input?: InputMaybe<AdminUpsertContextualDiscoveryCategoryLocationInput>;
};


export type MutationAdminRejectProductSuggestionArgs = {
  productRequestUpdateId: Scalars['ID']['input'];
};


export type MutationAdminToggleContextualDiscoveryActiveArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAdminUpsertBrandArgs = {
  input: AdminUpsertBrandInput;
};


export type MutationAdminUpsertBrandNameArgs = {
  input: AdminUpsertBrandNameInput;
};


export type MutationAdminUpsertContextualDiscoveryCategoryArgs = {
  input?: InputMaybe<AdminUpsertContextualDiscoveryCategoryInput>;
};


export type MutationAdminUpsertContextualDiscoveryCategoryLocationArgs = {
  input?: InputMaybe<AdminUpsertContextualDiscoveryCategoryLocationInput>;
};


export type MutationAdminUpsertEmailArgs = {
  input: AdminUpsertEmailInput;
};


export type MutationAdminUpsertLocationCategoryArgs = {
  input: AdminUpsertLocationCategoryInput;
};


export type MutationAdminUpsertLocationOwnerArgs = {
  input: AdminUpsertLocationOwnerInput;
};


export type MutationCompleteCartProcessArgs = {
  input: CompleteCartProcessInput;
};


export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};


export type MutationCreateCategoryArgs = {
  input?: InputMaybe<CategoryInput>;
};


export type MutationCreateLocationArgs = {
  input?: InputMaybe<LocationInput>;
};


export type MutationCreateLocationRequestArgs = {
  input: LocationRequestInput;
};


export type MutationCreateLocationsFromBoundaryArgs = {
  input?: InputMaybe<CreateLocationsFromBoundaryInput>;
};


export type MutationCreatePaymentIntentArgs = {
  input: CreatePaymentIntentInput;
};


export type MutationCreateProductArgs = {
  input?: InputMaybe<ProductInput>;
};


export type MutationCreateReelArgs = {
  input: CreateReelInput;
};


export type MutationDecreaseCartItemArgs = {
  input: AddCartItemInput;
};


export type MutationDeleteBrandArgs = {
  brandId: Scalars['ID']['input'];
};


export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['ID']['input'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProductImageArgs = {
  input: DeleteProductImageInput;
};


export type MutationDeleteWarehousePackItemImageArgs = {
  input: DeleteWarehousePackItemImageInput;
};


export type MutationEmployeeAcceptInviteArgs = {
  locationId: Scalars['String']['input'];
};


export type MutationIncreaseCartItemArgs = {
  input: AddCartItemInput;
};


export type MutationMerchantAddDeliveryPackingOptionArgs = {
  input: MerchantAddDeliveryPackingOptionInput;
};


export type MutationMerchantAssignOffertToVariantArgs = {
  input: MerchantAssignOffertToVariantInput;
};


export type MutationMerchantAssignProductsToLocationArgs = {
  input?: InputMaybe<MerchantAssignProductsToLocationInput>;
};


export type MutationMerchantChangeOrderStatusArgs = {
  input: MerchantChangeOrderStatusInput;
};


export type MutationMerchantCompletePaymentProfileArgs = {
  input: MerchantCompletePaymentProfileInput;
};


export type MutationMerchantCreateLocationArgs = {
  input: MerchantCreateLocationInput;
};


export type MutationMerchantCreateOfferVariantArgs = {
  input: MerchantCreateOfferVariantInput;
};


export type MutationMerchantCreatePaymentProviderAccountArgs = {
  input: MerchantCreatePaymentProviderAccountInput;
};


export type MutationMerchantCreateWarehousePackArgs = {
  locationId: Scalars['String']['input'];
};


export type MutationMerchantDeleteCategoryShowcaseArgs = {
  categoryShowcaseId: Scalars['ID']['input'];
};


export type MutationMerchantDeleteDeliveryOptionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMerchantDeleteDeliveryPackingOptionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMerchantDeleteOfferArgs = {
  input: MerchantDeleteOfferInput;
};


export type MutationMerchantDeletePaymentMethodArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMerchantDeleteWarehousePackArgs = {
  warehousePackId: Scalars['ID']['input'];
};


export type MutationMerchantDeleteWarehousePackItemArgs = {
  warehousePackItemId: Scalars['ID']['input'];
};


export type MutationMerchantDisconnectOffertFromVariantArgs = {
  input: MerchantDisconnectOffertFromVariantInput;
};


export type MutationMerchantDisconnectProductFromCategoryShowcaseArgs = {
  input?: InputMaybe<MerchantDisconnectProductFromCategoryShowcaseInput>;
};


export type MutationMerchantInviteEmployeeArgs = {
  input: MerchantInviteEmployeeInput;
};


export type MutationMerchantMarkProductAsActiveArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationMerchantMarkProductAsDeactivatedArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationMerchantToggleActiveSaleArgs = {
  locationId: Scalars['ID']['input'];
};


export type MutationMerchantTogglePriceHiddenArgs = {
  locationId: Scalars['ID']['input'];
};


export type MutationMerchantUpdateDeliveryPackingOptionArgs = {
  input: MerchantUpdateDeliveryPackingOptionInput;
};


export type MutationMerchantUpdateLocationArgs = {
  input?: InputMaybe<MerchantUpdateLocationInput>;
};


export type MutationMerchantUpdateOfferArgs = {
  input: MerchantUpdateOfferInput;
};


export type MutationMerchantUpdatePaymentProviderAccountArgs = {
  input: MerchantUpdatePaymentProviderAccountInput;
};


export type MutationMerchantUpsertCategoryShowcaseArgs = {
  input?: InputMaybe<MerchantUpsertCategoryShowcaseInput>;
};


export type MutationMerchantUpsertDeliveryOptionArgs = {
  input: MerchantUpsertDeliveryOptionInput;
};


export type MutationMerchantUpsertDeliveryPackingOptionArgs = {
  input: MerchantUpsertDeliveryPackingOptionInput;
};


export type MutationMerchantUpsertLocationArgs = {
  input: MerchantUpsertLocationInput;
};


export type MutationMerchantUpsertPaymentMethodArgs = {
  input: MerchantUpsertPaymentMethodInput;
};


export type MutationMerchantUpsertWarehousePackItemArgs = {
  input: MerchantUpsertWarehousePackItemInput;
  mode: Scalars['String']['input'];
};


export type MutationNotificationMarkAsArgs = {
  input: NotificationMarkAsInput;
};


export type MutationRejectLocationRequestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveCartArgs = {
  cartId: Scalars['ID']['input'];
};


export type MutationRemoveCartItemArgs = {
  input: RemoveCartItemInput;
};


export type MutationSendVerificationCodeArgs = {
  phoneNumber: Scalars['String']['input'];
};


export type MutationSetCartDeliveryAddressArgs = {
  input: SetCartDeliveryAddressInput;
};


export type MutationSetCartGroupDeliveryOptionArgs = {
  input: SetCartGroupDeliveryOptionInput;
};


export type MutationSetCartGroupDeliveryPackingOptionArgs = {
  input: SetCartGroupDeliveryPackingOptionInput;
};


export type MutationSetCartGroupPaymentMethodArgs = {
  input: SetCartGroupPaymentMethodInput;
};


export type MutationSetCartItemNoteArgs = {
  cartItemId: Scalars['ID']['input'];
  note: Scalars['String']['input'];
};


export type MutationSetCartItemQuantityArgs = {
  input: SetCartItemQuantityInput;
};


export type MutationSetCartItemReplacementArgs = {
  input: SetCartItemReplacementInput;
};


export type MutationSetPhoneNumberArgs = {
  input: SetPhoneNumberInput;
};


export type MutationToggleFavouriteLocationArgs = {
  locationId: Scalars['ID']['input'];
};


export type MutationTryToCreateProductFromOnlineArgs = {
  gtin: Scalars['String']['input'];
};


export type MutationUpdateBrandArgs = {
  input: UpdateBrandInput;
};


export type MutationUpdateCategoryArgs = {
  category: UpdateCategoryInput;
};


export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};


export type MutationUpdateReelArgs = {
  input: UpdateReelInput;
};


export type MutationUpsertProductArgs = {
  input: UpsertProductInput;
};


export type MutationUpsertUserDeliveryAddressArgs = {
  input: UpsertUserDeliveryAddressInput;
};


export type MutationUpsertWarehousePackArgs = {
  input?: InputMaybe<UpsertWarehousePackInput>;
};

export type NearOffer = {
  __typename?: 'NearOffer';
  categoryId: Scalars['ID']['output'];
  distance?: Maybe<Scalars['Float']['output']>;
  location?: Maybe<Location>;
  locationId: Scalars['ID']['output'];
  offer?: Maybe<Offer>;
  offerId: Scalars['ID']['output'];
  product?: Maybe<Product>;
  productId: Scalars['ID']['output'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  readed?: Maybe<Scalars['Boolean']['output']>;
  recipient?: Maybe<Recipient>;
  sender?: Maybe<Sender>;
  title?: Maybe<Scalars['String']['output']>;
};

export type NotificationConfig = {
  __typename?: 'NotificationConfig';
  config?: Maybe<NotificationConfigData>;
  id: Scalars['ID']['output'];
};

export type NotificationConfigData = {
  __typename?: 'NotificationConfigData';
  android?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ios?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  web?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type NotificationMarkAsInput = {
  id: Scalars['ID']['input'];
  readed: Scalars['Boolean']['input'];
};

export type Offer = {
  __typename?: 'Offer';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lastPrice?: Maybe<Price>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['ID']['output']>;
  prices?: Maybe<Array<Maybe<Price>>>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['ID']['output']>;
  showcaseCategoryId?: Maybe<Scalars['ID']['output']>;
  taxRate?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variants?: Maybe<Array<Maybe<OfferVariantGroup>>>;
};

export type OfferVariant = {
  __typename?: 'OfferVariant';
  id: Scalars['ID']['output'];
  offerId: Scalars['ID']['output'];
  type: Scalars['String']['output'];
  variant: Scalars['String']['output'];
};

export type OfferVariantGroup = {
  __typename?: 'OfferVariantGroup';
  id: Scalars['ID']['output'];
  items?: Maybe<Array<Maybe<OfferVariant>>>;
  type: Scalars['String']['output'];
};

export type OfferVariantToOffer = {
  __typename?: 'OfferVariantToOffer';
  label?: Maybe<Scalars['String']['output']>;
  offerId: Scalars['ID']['output'];
  variantId: Scalars['ID']['output'];
};

export type OfferVariantType = {
  __typename?: 'OfferVariantType';
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type OffertsFromCategoryInput = {
  categoryId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type Order = {
  __typename?: 'Order';
  createdAt?: Maybe<Scalars['String']['output']>;
  deliveryAddress?: Maybe<UserAddress>;
  deliveryOption?: Maybe<DeliveryOption>;
  id: Scalars['ID']['output'];
  items?: Maybe<Array<Maybe<OrderItem>>>;
  location?: Maybe<Location>;
  packingOption?: Maybe<PackingOption>;
  paymentMethod?: Maybe<LocationPaymentMethod>;
  paymentStatus: PaymentStatus;
  purchaser?: Maybe<Purchaser>;
  status: OrderStatus;
  trackDelivery?: Maybe<TrackDelivery>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type OrderBy = {
  descriptor?: InputMaybe<OrderByDescriptor>;
  field?: InputMaybe<Scalars['String']['input']>;
};

export type OrderByDescriptor = {
  sort?: InputMaybe<OrderByDirection>;
};

export type OrderByDirection =
  | 'ASC'
  | 'DESC';

export type OrderItem = {
  __typename?: 'OrderItem';
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  offer?: Maybe<OrderItemOffer>;
  price?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type OrderItemOffer = {
  __typename?: 'OrderItemOffer';
  currency?: Maybe<Scalars['String']['output']>;
  offerId: Scalars['ID']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<OrderItemOfferProduct>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type OrderItemOfferProduct = {
  __typename?: 'OrderItemOfferProduct';
  description?: Maybe<Scalars['String']['output']>;
  ean?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  primaryImage?: Maybe<Image>;
};

export type OrderStatus =
  | 'Accepted'
  | 'Canceled'
  | 'Delivered'
  | 'Delivering'
  | 'Disputed'
  | 'Done'
  | 'Ordered'
  | 'Processing'
  | 'ReadyToPickUp'
  | 'Rejected';

export type PackingOption = {
  __typename?: 'PackingOption';
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  packingPrice?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedAdminContextualDiscovery = {
  __typename?: 'PaginatedAdminContextualDiscovery';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<AdminContextualDiscovery>>>;
};

export type PaginatedBrands = {
  __typename?: 'PaginatedBrands';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Brand>>>;
};

export type PaginatedBusiness = {
  __typename?: 'PaginatedBusiness';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Business>>>;
};

export type PaginatedFilterInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PaginatedInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<InputMaybe<PaginatedFilterInput>>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
};

export type PaginatedLocation = {
  __typename?: 'PaginatedLocation';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Location>>>;
};

export type PaginatedLocationRequests = {
  __typename?: 'PaginatedLocationRequests';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<LocationRequest>>>;
};

export type PaginatedOfferts = {
  __typename?: 'PaginatedOfferts';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Offer>>>;
};

export type PaginatedProducts = {
  __typename?: 'PaginatedProducts';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Product>>>;
};

export type PaginatedTaxes = {
  __typename?: 'PaginatedTaxes';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Tax>>>;
};

export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<AdminUser>>>;
};

export type PaginatedWarehouseProducts = {
  __typename?: 'PaginatedWarehouseProducts';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<WarehouseProductData>>>;
};

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['ID']['output'];
  metadata?: Maybe<PaymentMetadata>;
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  customer: Scalars['String']['output'];
  ephemeralKey: Scalars['String']['output'];
  paymentIntent: Scalars['String']['output'];
};

export type PaymentMetadata = {
  __typename?: 'PaymentMetadata';
  customerId?: Maybe<Scalars['String']['output']>;
  ephemeralKeySecret?: Maybe<Scalars['String']['output']>;
  paymentIntentClientSecret?: Maybe<Scalars['String']['output']>;
  paymentIntentId?: Maybe<Scalars['String']['output']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  active?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: LocationPaymentMethodType;
};

export type PaymentProfile = {
  __typename?: 'PaymentProfile';
  active?: Maybe<Scalars['Boolean']['output']>;
  businessId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  locationId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  providerId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PaymentStatus =
  | 'Failed'
  | 'Paid'
  | 'Pending'
  | 'Refunded';

export type PopularLocationsInput = {
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
};

export type PopularSearchQuery = {
  __typename?: 'PopularSearchQuery';
  id: Scalars['ID']['output'];
  q: Scalars['String']['output'];
};

export type Price = {
  __typename?: 'Price';
  currency?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type Product = {
  __typename?: 'Product';
  active?: Maybe<Scalars['Boolean']['output']>;
  brand?: Maybe<Brand>;
  brandId?: Maybe<Scalars['String']['output']>;
  brandName?: Maybe<BrandName>;
  brandNameId?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  ean?: Maybe<Scalars['String']['output']>;
  existing?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Image>>>;
  lastPrice?: Maybe<Price>;
  locations?: Maybe<Array<Maybe<Location>>>;
  name: Scalars['String']['output'];
  offers?: Maybe<Array<Maybe<Offer>>>;
  priceHistory?: Maybe<Array<Maybe<Price>>>;
  primaryImage?: Maybe<Image>;
  showcaseCategory?: Maybe<ShowcaseCategory>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  variants?: Maybe<Array<Maybe<OfferVariantGroup>>>;
};


export type ProductLastPriceArgs = {
  locationId?: InputMaybe<Scalars['ID']['input']>;
};


export type ProductPriceHistoryArgs = {
  input?: InputMaybe<ProductWithFiltersInput>;
};


export type ProductVariantsArgs = {
  locationId: Scalars['ID']['input'];
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ProductFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ProductFilter2 = {
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProductFromOnline = {
  __typename?: 'ProductFromOnline';
  brand?: Maybe<Scalars['String']['output']>;
  categoryDetails?: Maybe<Array<Maybe<ProductFromOnlineCategoryDetail>>>;
  company?: Maybe<ProductFromOnlineCompany>;
  description?: Maybe<Scalars['String']['output']>;
  gcp?: Maybe<Array<Maybe<ProductFromOnlineGpc>>>;
  imageUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  netContent?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  netVolume?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type ProductFromOnlineCategoryDetail = {
  __typename?: 'ProductFromOnlineCategoryDetail';
  code?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type ProductFromOnlineCompany = {
  __typename?: 'ProductFromOnlineCompany';
  address?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['Json']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
};

export type ProductFromOnlineGpc = {
  __typename?: 'ProductFromOnlineGpc';
  code?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type ProductInput = {
  description: Scalars['String']['input'];
  ean?: InputMaybe<Scalars['String']['input']>;
  image: Scalars['String']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
};

export type ProductSuggestion = {
  __typename?: 'ProductSuggestion';
  createdAt?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['Json']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  rejectedMessage?: Maybe<Scalars['String']['output']>;
  rejectedType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ProductWithFiltersInput = {
  filters?: InputMaybe<Array<InputMaybe<ProductFilter>>>;
};

export type ProductsFromCategoryInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  cursor?: InputMaybe<Scalars['ID']['input']>;
  filters?: InputMaybe<Array<InputMaybe<ProductFilter>>>;
  locationId: Scalars['ID']['input'];
  parentCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProductsInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<InputMaybe<ProductFilter>>>;
};

export type ProductsInput2 = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<InputMaybe<ProductFilter2>>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
};

export type Purchaser = {
  __typename?: 'Purchaser';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  ad?: Maybe<Ad>;
  adminBrand?: Maybe<AdminBrand>;
  adminBrandNames?: Maybe<Array<Maybe<BrandName>>>;
  adminBrands: PaginatedBrands;
  adminBusiness?: Maybe<Business>;
  adminBusinessOwners?: Maybe<Array<Maybe<AdminBusinessOwner>>>;
  adminBusinesses: PaginatedBusiness;
  adminContextualDiscoveries?: Maybe<PaginatedAdminContextualDiscovery>;
  adminContextualDiscovery?: Maybe<AdminContextualDiscovery>;
  adminEmail?: Maybe<Email>;
  adminFindProductDuplicate?: Maybe<Array<Maybe<Product>>>;
  adminListEmails?: Maybe<Array<Maybe<Email>>>;
  adminLocation?: Maybe<AdminLocation>;
  adminLocations: PaginatedLocation;
  adminLocationsSearch?: Maybe<Array<Maybe<Location>>>;
  adminProductToVerify?: Maybe<AdminProductToVerify>;
  adminProducts: PaginatedProducts;
  adminProductsToVerify?: Maybe<AdminPaginatedProductsToVerify>;
  adminProductsToVerifyCount: Scalars['Int']['output'];
  adminSearchProduct?: Maybe<ProductFromOnline>;
  adminTaxes: PaginatedTaxes;
  adminUser?: Maybe<AdminUser>;
  adminUsers?: Maybe<PaginatedUsers>;
  ads?: Maybe<Array<Maybe<Ad>>>;
  availableTaxes?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  brand?: Maybe<Brand>;
  brands: PaginatedBrands;
  brandsFromCategory: Array<Maybe<CategoriesFromProductsResult>>;
  calculateShoppingCart?: Maybe<Scalars['Json']['output']>;
  categoriesFromParent: Array<Maybe<CategoriesFromProductsResult>>;
  categoriesFromProducts: Array<Maybe<CategoriesFromProductsResult>>;
  category: Category;
  cityList?: Maybe<Array<Maybe<DiscoveryCity>>>;
  countProducts: Scalars['Int']['output'];
  counts?: Maybe<Counts>;
  deliveryPricing?: Maybe<DeliveryPricing>;
  discoveryLocation?: Maybe<Discovery>;
  favouritesProducts: Array<Product>;
  findNearestCity?: Maybe<City>;
  findNearestPlaceForProduct?: Maybe<Array<Maybe<Location>>>;
  findProduct?: Maybe<Product>;
  listNearOffers?: Maybe<Array<Maybe<NearOffer>>>;
  listOffersByCategory?: Maybe<Array<Maybe<Offer>>>;
  location?: Maybe<Location>;
  locationDeliveryOptions: Array<Maybe<DeliveryOption>>;
  locationDeliveryPackingOptions: Array<Maybe<DeliveryPackingOption>>;
  locationPaymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
  locationRequests?: Maybe<PaginatedLocationRequests>;
  locations?: Maybe<PaginatedLocation>;
  locationsForProduct: Array<Location>;
  locationsFromBoundary?: Maybe<Array<Location>>;
  me: User;
  merchantActiveCarts?: Maybe<Scalars['Json']['output']>;
  merchantBusinessFirstLocation: Location;
  merchantBusinessSetupProcess: BusinessSetupProcess;
  merchantCategoriesFromProducts?: Maybe<Array<Maybe<Category>>>;
  merchantDelivery?: Maybe<MerchantDelivery>;
  merchantDeliveryOption?: Maybe<MerchantDeliveryOption>;
  merchantEmployee?: Maybe<Employee>;
  merchantGetDeliveryPackingOptions?: Maybe<Array<Maybe<DeliveryPackingOption>>>;
  merchantIsOfferExists?: Maybe<Offer>;
  merchantListEmployees?: Maybe<Array<Maybe<Employee>>>;
  merchantLocation?: Maybe<MerchantLocation>;
  merchantLocationCategory?: Maybe<Array<Maybe<LocationCategory>>>;
  merchantMyBusiness: Business;
  merchantOffer?: Maybe<Offer>;
  merchantOfferVariantTypes: Array<OfferVariantType>;
  merchantOrder?: Maybe<Order>;
  merchantOrders?: Maybe<Array<Maybe<Order>>>;
  merchantProduct?: Maybe<Product>;
  merchantProducts: PaginatedProducts;
  merchantWarehousePack?: Maybe<WarehousePack>;
  merchantWarehousePackItem?: Maybe<WarehouseProduct>;
  merchantWarehousePacks?: Maybe<Array<Maybe<WarehousePack>>>;
  merchantWarehouseProducts: PaginatedWarehouseProducts;
  myAddresses: Array<UserAddress>;
  myLocations: Array<Location>;
  myOrders?: Maybe<Array<Maybe<Order>>>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  offer?: Maybe<Offer>;
  offersShowcase: Array<Maybe<ShowcaseCategory>>;
  offertsFromCategory: PaginatedOfferts;
  order?: Maybe<Order>;
  popularLocations: Array<Location>;
  popularSearchQuery?: Maybe<Array<Maybe<PopularSearchQuery>>>;
  product?: Maybe<Product>;
  productCategories: Array<Category>;
  products: PaginatedProducts;
  productsByLocationId: Array<CategoryWithProducts>;
  productsFromCategory: PaginatedOfferts;
  search?: Maybe<SearchResultResponse>;
  searchBrandOnline?: Maybe<Scalars['Json']['output']>;
  searchProducts: SearchProduct;
  setupIntent: SetupIntent;
  shoppingCarts?: Maybe<Array<Maybe<ShoppingCart>>>;
  stories?: Maybe<Array<Maybe<Ad>>>;
  tryToFindProductOnline?: Maybe<Scalars['Json']['output']>;
  validateTaxId?: Maybe<CompanyInfo>;
};


export type QueryAdArgs = {
  adId: Scalars['ID']['input'];
};


export type QueryAdminBrandArgs = {
  brandId: Scalars['ID']['input'];
};


export type QueryAdminBrandsArgs = {
  input?: InputMaybe<BrandsInput>;
};


export type QueryAdminBusinessArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryAdminBusinessOwnersArgs = {
  input: AdminBusinessOwnersInput;
};


export type QueryAdminBusinessesArgs = {
  input?: InputMaybe<PaginatedInput>;
};


export type QueryAdminContextualDiscoveriesArgs = {
  input?: InputMaybe<AdminContextualDiscoveryInput>;
};


export type QueryAdminContextualDiscoveryArgs = {
  contextualDiscoveryId: Scalars['ID']['input'];
};


export type QueryAdminEmailArgs = {
  emailTemplateId: Scalars['String']['input'];
};


export type QueryAdminFindProductDuplicateArgs = {
  input: AdminFindProductDuplicateInput;
};


export type QueryAdminLocationArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryAdminLocationsArgs = {
  input?: InputMaybe<PaginatedInput>;
};


export type QueryAdminLocationsSearchArgs = {
  input?: InputMaybe<AdminLocationsSearchInput>;
};


export type QueryAdminProductToVerifyArgs = {
  productSuggestionId: Scalars['ID']['input'];
};


export type QueryAdminProductsArgs = {
  input?: InputMaybe<ProductsInput2>;
};


export type QueryAdminProductsToVerifyArgs = {
  input?: InputMaybe<AdminProductsToVerifyInput>;
};


export type QueryAdminSearchProductArgs = {
  gtin?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAdminTaxesArgs = {
  input?: InputMaybe<PaginatedInput>;
};


export type QueryAdminUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdminUsersArgs = {
  input: PaginatedInput;
};


export type QueryAdsArgs = {
  input: AdsInput;
};


export type QueryBrandArgs = {
  brandId: Scalars['ID']['input'];
};


export type QueryBrandsArgs = {
  input?: InputMaybe<BrandsInput>;
};


export type QueryBrandsFromCategoryArgs = {
  input: BrandsFromCategoryInput;
};


export type QueryCalculateShoppingCartArgs = {
  shoppingCartId: Scalars['ID']['input'];
};


export type QueryCategoriesFromParentArgs = {
  input: CategoriesFromParentInput;
};


export type QueryCategoriesFromProductsArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryCategoryArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCityListArgs = {
  input?: InputMaybe<CityListInput>;
};


export type QueryCountProductsArgs = {
  input?: InputMaybe<CountProductsInput>;
};


export type QueryDeliveryPricingArgs = {
  input: DeliveryPricingInput;
};


export type QueryDiscoveryLocationArgs = {
  input: DiscoveryLocationInput;
};


export type QueryFavouritesProductsArgs = {
  input?: InputMaybe<FavouritesProductsInput>;
};


export type QueryFindNearestCityArgs = {
  lat: Scalars['Float']['input'];
  lon: Scalars['Float']['input'];
};


export type QueryFindNearestPlaceForProductArgs = {
  input: FindNearestPlaceForProductInput;
};


export type QueryFindProductArgs = {
  filters?: InputMaybe<Array<InputMaybe<ProductFilter>>>;
};


export type QueryListNearOffersArgs = {
  input: ListOffersInput;
};


export type QueryListOffersByCategoryArgs = {
  input: ListOffersByCategoryInput;
};


export type QueryLocationArgs = {
  id: Scalars['ID']['input'];
  includeProducts?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryLocationDeliveryOptionsArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryLocationDeliveryPackingOptionsArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryLocationPaymentMethodsArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryLocationRequestsArgs = {
  input?: InputMaybe<LocationRequestsInput>;
};


export type QueryLocationsArgs = {
  input: LocationsInput;
};


export type QueryLocationsForProductArgs = {
  input: LocationsForProductInput;
};


export type QueryLocationsFromBoundaryArgs = {
  boundary: Array<InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>>;
  location: Array<InputMaybe<Scalars['Float']['input']>>;
};


export type QueryMerchantActiveCartsArgs = {
  input: MerchantActiveCartsInput;
};


export type QueryMerchantCategoriesFromProductsArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryMerchantDeliveryArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryMerchantDeliveryOptionArgs = {
  deliveryOptionId: Scalars['ID']['input'];
};


export type QueryMerchantEmployeeArgs = {
  employeeId: Scalars['ID']['input'];
};


export type QueryMerchantGetDeliveryPackingOptionsArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryMerchantIsOfferExistsArgs = {
  ean: Scalars['String']['input'];
  locationId: Scalars['ID']['input'];
};


export type QueryMerchantListEmployeesArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryMerchantLocationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMerchantOfferArgs = {
  offerId: Scalars['ID']['input'];
};


export type QueryMerchantOfferVariantTypesArgs = {
  offerId: Scalars['ID']['input'];
};


export type QueryMerchantOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMerchantOrdersArgs = {
  input: MerchantOrdersInput;
};


export type QueryMerchantProductArgs = {
  input?: InputMaybe<ProductWithFiltersInput>;
};


export type QueryMerchantProductsArgs = {
  input?: InputMaybe<ProductsInput>;
};


export type QueryMerchantWarehousePackArgs = {
  warehousePackId: Scalars['ID']['input'];
};


export type QueryMerchantWarehousePackItemArgs = {
  warehousePackItemId: Scalars['ID']['input'];
};


export type QueryMerchantWarehousePacksArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryMerchantWarehouseProductsArgs = {
  input?: InputMaybe<ProductsInput>;
};


export type QueryMyAddressesArgs = {
  type: AddressType;
};


export type QueryOfferArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOffersShowcaseArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryOffertsFromCategoryArgs = {
  input: OffertsFromCategoryInput;
};


export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPopularLocationsArgs = {
  input?: InputMaybe<PopularLocationsInput>;
};


export type QueryProductArgs = {
  id: Scalars['ID']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProductsArgs = {
  input?: InputMaybe<ProductsInput2>;
};


export type QueryProductsByLocationIdArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryProductsFromCategoryArgs = {
  input: ProductsFromCategoryInput;
  locationId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerySearchArgs = {
  input: SearchInput;
};


export type QuerySearchBrandOnlineArgs = {
  input: SearchBrandOnlineInput;
};


export type QuerySearchProductsArgs = {
  input?: InputMaybe<SearchProductsInput>;
};


export type QueryStoriesArgs = {
  input?: InputMaybe<StoriesInput>;
};


export type QueryTryToFindProductOnlineArgs = {
  input?: InputMaybe<TryToFindProductOnlineInput>;
};


export type QueryValidateTaxIdArgs = {
  taxId: Scalars['String']['input'];
};

export type Recipient = {
  __typename?: 'Recipient';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Reel = {
  __typename?: 'Reel';
  addons?: Maybe<Scalars['Json']['output']>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  source: Scalars['String']['output'];
  story?: Maybe<Story>;
  type: ReelType;
  updatedAt: Scalars['String']['output'];
};

export type ReelType =
  | 'Image'
  | 'Video';

export type RejectLocationRequestResponse = {
  __typename?: 'RejectLocationRequestResponse';
  error?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveCartItemInput = {
  cartItemId: Scalars['ID']['input'];
};

export type ReplacementType =
  | 'OwnerReplacement'
  | 'Refund'
  | 'SelfReplacement';

export type ResponseStatus =
  | 'Error'
  | 'Success';

export type SearchBrandOnlineInput = {
  country: Scalars['String']['input'];
  taxId: Scalars['String']['input'];
};

export type SearchInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pos: Array<InputMaybe<Scalars['Float']['input']>>;
  text: Scalars['String']['input'];
};

export type SearchProduct = {
  __typename?: 'SearchProduct';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Product>>>;
};

export type SearchProductsInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  categoryId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  offerCount?: Maybe<Scalars['Int']['output']>;
};

export type SearchResultResponse = {
  __typename?: 'SearchResultResponse';
  page?: Maybe<Scalars['Int']['output']>;
  results?: Maybe<Array<Maybe<SearchResult>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Sender = {
  __typename?: 'Sender';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type SetCartDeliveryAddressInput = {
  cartId: Scalars['ID']['input'];
  deliveryAddressId?: InputMaybe<Scalars['ID']['input']>;
  oneTimeDeliveryAddress?: InputMaybe<Scalars['Json']['input']>;
};

export type SetCartGroupDeliveryOptionInput = {
  cartGroupId: Scalars['ID']['input'];
  deliveryOptionId?: InputMaybe<Scalars['ID']['input']>;
};

export type SetCartGroupDeliveryPackingOptionInput = {
  cartGroupId: Scalars['ID']['input'];
  deliveryPackingOptionId: Scalars['ID']['input'];
};

export type SetCartGroupPaymentMethodInput = {
  cartGroupId: Scalars['ID']['input'];
  paymentMethodId: Scalars['ID']['input'];
};

export type SetCartItemQuantityDataResponse = {
  __typename?: 'SetCartItemQuantityDataResponse';
  cartItemId?: Maybe<Scalars['ID']['output']>;
};

export type SetCartItemQuantityInput = {
  locationId: Scalars['ID']['input'];
  offerId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export type SetCartItemQuantityResponse = {
  __typename?: 'SetCartItemQuantityResponse';
  data?: Maybe<SetCartItemQuantityDataResponse>;
  error?: Maybe<Scalars['String']['output']>;
};

export type SetCartItemReplacementInput = {
  cartItemId: Scalars['ID']['input'];
  replacementId?: InputMaybe<Scalars['ID']['input']>;
  replacementType?: InputMaybe<ReplacementType>;
};

export type SetPhoneNumberInput = {
  phoneNumber: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type SetupIntent = {
  __typename?: 'SetupIntent';
  customer: Scalars['String']['output'];
  ephemeralKey: Scalars['String']['output'];
  setupIntent: Scalars['String']['output'];
};

export type ShoppingCart = {
  __typename?: 'ShoppingCart';
  createdAt: Scalars['String']['output'];
  customerId?: Maybe<Scalars['String']['output']>;
  deliveryAddress?: Maybe<UserAddress>;
  deliveryAddressCopy?: Maybe<UserAddressCopy>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryOptionCopy?: Maybe<DeliveryOptionCopy>;
  deliveryPackingOption?: Maybe<DeliveryPackingOption>;
  deliveryPackingOptionCopy?: Maybe<DeliveryPackingOptionCopy>;
  ephemeralKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  items: Array<CartItem>;
  location: Location;
  payment?: Maybe<Payment>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodId?: Maybe<Scalars['ID']['output']>;
  stripeIntentClientSecret?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type ShowcaseCategory = {
  __typename?: 'ShowcaseCategory';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  offers: Array<Maybe<Offer>>;
  order?: Maybe<Scalars['Int']['output']>;
  targetCategoryId?: Maybe<Scalars['ID']['output']>;
};

export type StoriesInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type Story = {
  __typename?: 'Story';
  ad?: Maybe<Ad>;
  adId?: Maybe<Scalars['ID']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  reels?: Maybe<Array<Maybe<Reel>>>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type Tax = {
  __typename?: 'Tax';
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  rate: Scalars['Int']['output'];
  updatedAt: Scalars['String']['output'];
};

export type TrackDelivery = {
  __typename?: 'TrackDelivery';
  estimatedDeliveryTime?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type TryToFindProductOnlineInput = {
  createOnFind?: InputMaybe<Scalars['Boolean']['input']>;
  gtin: Scalars['String']['input'];
};

export type UpdateBrandInput = {
  id: Scalars['ID']['input'];
  meta?: InputMaybe<Scalars['Json']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBrandResult = {
  __typename?: 'UpdateBrandResult';
  id: Scalars['ID']['output'];
};

export type UpdateCategoryInput = {
  id: Scalars['ID']['input'];
  meta?: InputMaybe<Scalars['Json']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
  taxId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateProductInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  brand?: InputMaybe<BrandInput>;
  brandId?: InputMaybe<Scalars['String']['input']>;
  brandNameId?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  ean?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  images?: InputMaybe<Array<InputMaybe<Scalars['File']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductResult = {
  __typename?: 'UpdateProductResult';
  id: Scalars['ID']['output'];
};

export type UpdateReelInput = {
  file: Scalars['File']['input'];
  reelId: Scalars['ID']['input'];
};

export type UpsertProductInput = {
  brandId?: InputMaybe<Scalars['ID']['input']>;
  brandNameId?: InputMaybe<Scalars['ID']['input']>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  ean?: InputMaybe<Scalars['String']['input']>;
  existingImages?: InputMaybe<Array<InputMaybe<ExistingImageInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['File']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertUserDeliveryAddressInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  apartmentNumber?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  floor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  street: Scalars['String']['input'];
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  zipNumber: Scalars['String']['input'];
};

export type UpsertWarehousePackInput = {
  item?: InputMaybe<UpsertWarehousePackProductInput>;
  locationId: Scalars['String']['input'];
  warehousePackId?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertWarehousePackProductInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  ean?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['Json']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpsertWarehousePackResult = {
  __typename?: 'UpsertWarehousePackResult';
  lastAddedItem?: Maybe<WarehouseProduct>;
  warehousePack?: Maybe<WarehousePack>;
};

export type User = {
  __typename?: 'User';
  businessId?: Maybe<Scalars['ID']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  notifications?: Maybe<NotificationConfig>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type UserAddress = {
  __typename?: 'UserAddress';
  address?: Maybe<Scalars['Json']['output']>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type UserAddressCopy = {
  __typename?: 'UserAddressCopy';
  address?: Maybe<Scalars['Json']['output']>;
  createdAt: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type UserAddressResponse = {
  __typename?: 'UserAddressResponse';
  data?: Maybe<UserAddress>;
  response: UserAddressResponseMetadata;
};

export type UserAddressResponseMetadata = {
  __typename?: 'UserAddressResponseMetadata';
  message?: Maybe<Scalars['String']['output']>;
  status: ResponseStatus;
};

export type UserBillAddressInput = {
  apartmentNumber?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  street: Scalars['String']['input'];
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  zipNumber: Scalars['String']['input'];
};

export type UserInvoiceAddressInput = {
  apartmentNumber?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  street: Scalars['String']['input'];
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  taxNumber: Scalars['String']['input'];
  zipNumber: Scalars['String']['input'];
};

export type WarehousePack = {
  __typename?: 'WarehousePack';
  _count?: Maybe<WarehousePackNestedCount>;
  createdAt?: Maybe<Scalars['String']['output']>;
  done?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  items?: Maybe<Array<WarehouseProduct>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type WarehousePackNestedCount = {
  __typename?: 'WarehousePackNestedCount';
  items?: Maybe<Scalars['Int']['output']>;
};

export type WarehouseProduct = {
  __typename?: 'WarehouseProduct';
  createdAt?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  ean?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Scalars['Json']['output']>;
  meta?: Maybe<Scalars['Json']['output']>;
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['ID']['output']>;
  taxRate?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  warehousePackId: Scalars['ID']['output'];
  withoutEan?: Maybe<Scalars['Boolean']['output']>;
};

export type WarehouseProductData = {
  __typename?: 'WarehouseProductData';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  location: Location;
  product: Product;
};

export type Website = {
  __typename?: 'Website';
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type WebsiteInput = {
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type AddCartItemMutationVariables = Exact<{
  input: AddCartItemInput;
}>;


export type AddCartItemMutation = { __typename?: 'Mutation', addCartItem?: { __typename?: 'AddCartItemResponse', cartItemId?: string | null } | null };

export type CalculateShoppingCartQueryVariables = Exact<{
  shoppingCartId: Scalars['ID']['input'];
}>;


export type CalculateShoppingCartQuery = { __typename?: 'Query', calculateShoppingCart?: any | null };

export type CompleteCartProcessMutationVariables = Exact<{
  input: CompleteCartProcessInput;
}>;


export type CompleteCartProcessMutation = { __typename?: 'Mutation', completeCartProcess: { __typename?: 'CompleteCartProcessResponse', data?: { __typename?: 'CompleteCartProcessData', orderId: string } | null } };

export type CreatePaymentIntentMutationVariables = Exact<{
  input: CreatePaymentIntentInput;
}>;


export type CreatePaymentIntentMutation = { __typename?: 'Mutation', createPaymentIntent?: { __typename?: 'PaymentIntent', paymentIntent: string, ephemeralKey: string, customer: string } | null };

export type DecreaseCartItemMutationVariables = Exact<{
  input: AddCartItemInput;
}>;


export type DecreaseCartItemMutation = { __typename?: 'Mutation', decreaseCartItem: boolean };

export type IncreaseCartItemMutationVariables = Exact<{
  input: AddCartItemInput;
}>;


export type IncreaseCartItemMutation = { __typename?: 'Mutation', increaseCartItem: boolean };

export type LocationDeliveryOptionsQueryVariables = Exact<{
  locationId: Scalars['ID']['input'];
}>;


export type LocationDeliveryOptionsQuery = { __typename?: 'Query', locationDeliveryOptions: Array<{ __typename?: 'DeliveryOption', id: string, type: DeliveryType, description: string, deliveryPrice: number, currency: string, radius?: number | null } | null> };

export type LocationDeliveryPackingOptionsQueryVariables = Exact<{
  locationId: Scalars['ID']['input'];
}>;


export type LocationDeliveryPackingOptionsQuery = { __typename?: 'Query', locationDeliveryPackingOptions: Array<{ __typename?: 'DeliveryPackingOption', id: string, name: string, description?: string | null, packingPrice: number } | null> };

export type LocationPaymentMethodsQueryVariables = Exact<{
  locationId: Scalars['ID']['input'];
}>;


export type LocationPaymentMethodsQuery = { __typename?: 'Query', locationPaymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, name: string, type: LocationPaymentMethodType, active?: boolean | null, locationId: string } | null> | null };

export type RelatedProductsByCategoryQueryVariables = Exact<{
  input: OffertsFromCategoryInput;
}>;


export type RelatedProductsByCategoryQuery = { __typename?: 'Query', offertsFromCategory: { __typename?: 'PaginatedOfferts', data?: Array<{ __typename?: 'Offer', id: string, product?: { __typename?: 'Product', name: string, images?: Array<{ __typename?: 'Image', url?: string | null } | null> | null } | null, lastPrice?: { __typename?: 'Price', value?: number | null, date?: string | null } | null } | null> | null } };

export type RemoveCartMutationVariables = Exact<{
  cartId: Scalars['ID']['input'];
}>;


export type RemoveCartMutation = { __typename?: 'Mutation', removeCart: boolean };

export type RemoveCartItemMutationVariables = Exact<{
  input: RemoveCartItemInput;
}>;


export type RemoveCartItemMutation = { __typename?: 'Mutation', removeCartItem?: boolean | null };

export type SetCartDeliveryAddressMutationVariables = Exact<{
  input: SetCartDeliveryAddressInput;
}>;


export type SetCartDeliveryAddressMutation = { __typename?: 'Mutation', setCartDeliveryAddress: { __typename?: 'UserAddressResponse', response: { __typename?: 'UserAddressResponseMetadata', status: ResponseStatus, message?: string | null }, data?: { __typename?: 'UserAddress', id: string } | null } };

export type SetCartGroupDeliveryOptionMutationVariables = Exact<{
  input: SetCartGroupDeliveryOptionInput;
}>;


export type SetCartGroupDeliveryOptionMutation = { __typename?: 'Mutation', setCartGroupDeliveryOption: boolean };

export type SetCartGroupDeliveryPackingOptionMutationVariables = Exact<{
  input: SetCartGroupDeliveryPackingOptionInput;
}>;


export type SetCartGroupDeliveryPackingOptionMutation = { __typename?: 'Mutation', setCartGroupDeliveryPackingOption: boolean };

export type SetCartGroupPaymentMethodMutationVariables = Exact<{
  input: SetCartGroupPaymentMethodInput;
}>;


export type SetCartGroupPaymentMethodMutation = { __typename?: 'Mutation', setCartGroupPaymentMethod: boolean };

export type SetCartItemNoteMutationVariables = Exact<{
  cartItemId: Scalars['ID']['input'];
  note: Scalars['String']['input'];
}>;


export type SetCartItemNoteMutation = { __typename?: 'Mutation', setCartItemNote: boolean };

export type SetCartItemQuantityMutationVariables = Exact<{
  input: SetCartItemQuantityInput;
}>;


export type SetCartItemQuantityMutation = { __typename?: 'Mutation', setCartItemQuantity?: { __typename?: 'SetCartItemQuantityResponse', error?: string | null, data?: { __typename?: 'SetCartItemQuantityDataResponse', cartItemId?: string | null } | null } | null };

export type SetCartItemReplacementMutationVariables = Exact<{
  input: SetCartItemReplacementInput;
}>;


export type SetCartItemReplacementMutation = { __typename?: 'Mutation', setCartItemReplacement: boolean };

export type ShopLocationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  includeProducts?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ShopLocationQuery = { __typename?: 'Query', location?: { __typename?: 'Location', longitude?: number | null, latitude?: number | null } | null };

export type ShoppingCartsQueryVariables = Exact<{ [key: string]: never; }>;


export type ShoppingCartsQuery = { __typename?: 'Query', shoppingCarts?: Array<{ __typename?: 'ShoppingCart', id: string, stripeIntentClientSecret?: string | null, customerId?: string | null, paymentMethodId?: string | null, deliveryOption?: { __typename?: 'DeliveryOption', id: string, type: DeliveryType, description: string, deliveryPrice: number } | null, deliveryOptionCopy?: { __typename?: 'DeliveryOptionCopy', type: string, description: string, deliveryPrice: number } | null, deliveryPackingOption?: { __typename?: 'DeliveryPackingOption', id: string, name: string, description?: string | null, packingPrice: number } | null, deliveryAddressCopy?: { __typename?: 'UserAddressCopy', id?: string | null, address?: any | null } | null, deliveryPackingOptionCopy?: { __typename?: 'DeliveryPackingOptionCopy', id: string, name: string, description?: string | null, packingPrice: number } | null, payment?: { __typename?: 'Payment', metadata?: { __typename?: 'PaymentMetadata', paymentIntentId?: string | null, ephemeralKeySecret?: string | null, customerId?: string | null, paymentIntentClientSecret?: string | null } | null } | null, paymentMethod?: { __typename?: 'PaymentMethod', type: LocationPaymentMethodType } | null, location: { __typename?: 'Location', id: string, name?: string | null, images?: Array<{ __typename?: 'Image', id?: string | null, url?: string | null, size?: string | null } | null> | null }, items: Array<{ __typename?: 'CartItem', id: string, createdAt: string, price: number, quantity: number, note?: string | null, replacement?: { __typename?: 'Offer', id: string } | null, offer: { __typename?: 'Offer', id: string, product?: { __typename?: 'Product', name: string, categoryId?: string | null, primaryImage?: { __typename?: 'Image', url?: string | null } | null } | null } }> } | null> | null };

export type DiscoveryLocationQueryVariables = Exact<{
  input: DiscoveryLocationInput;
}>;


export type DiscoveryLocationQuery = { __typename?: 'Query', discoveryLocation?: { __typename?: 'Discovery', id?: string | null, name?: string | null, latitude?: number | null, longitude?: number | null, categories?: Array<{ __typename?: 'DiscoveryCategory', id: string, name: string, locations?: Array<{ __typename?: 'Location', id: string, name?: string | null, distance?: number | null, images?: Array<{ __typename?: 'Image', id?: string | null, url?: string | null, blurhash?: string | null, size?: string | null } | null> | null } | null> | null } | null> | null } | null };

export type ListNearOffersQueryVariables = Exact<{
  input: ListOffersInput;
}>;


export type ListNearOffersQuery = { __typename?: 'Query', listNearOffers?: Array<{ __typename?: 'NearOffer', distance?: number | null, location?: { __typename?: 'Location', id: string, name?: string | null, address?: string | null, images?: Array<{ __typename?: 'Image', url?: string | null } | null> | null } | null, product?: { __typename?: 'Product', id: string, name: string, category?: { __typename?: 'Category', id?: string | null } | null, images?: Array<{ __typename?: 'Image', url?: string | null } | null> | null } | null, offer?: { __typename?: 'Offer', id: string } | null } | null> | null };

export type ListOffersByCategoryQueryVariables = Exact<{
  input: ListOffersByCategoryInput;
}>;


export type ListOffersByCategoryQuery = { __typename?: 'Query', listOffersByCategory?: Array<{ __typename?: 'Offer', id: string, product?: { __typename?: 'Product', name: string, images?: Array<{ __typename?: 'Image', url?: string | null } | null> | null, category?: { __typename?: 'Category', name?: string | null } | null } | null } | null> | null };

export type SearchQueryVariables = Exact<{
  input: SearchInput;
}>;


export type SearchQuery = { __typename?: 'Query', search?: { __typename?: 'SearchResultResponse', page?: number | null, total?: number | null, results?: Array<{ __typename?: 'SearchResult', id: string, name: string, image?: string | null, categoryId?: string | null, offerCount?: number | null } | null> | null } | null };

export type BrandsFromCategoryQueryVariables = Exact<{
  input: BrandsFromCategoryInput;
}>;


export type BrandsFromCategoryQuery = { __typename?: 'Query', brandsFromCategory: Array<{ __typename?: 'CategoriesFromProductsResult', id: string, name: string } | null> };

export type CategoriesFromParentQueryVariables = Exact<{
  input: CategoriesFromParentInput;
}>;


export type CategoriesFromParentQuery = { __typename?: 'Query', categoriesFromParent: Array<{ __typename?: 'CategoriesFromProductsResult', id: string, name: string } | null> };

export type CategoriesFromProductsQueryVariables = Exact<{
  locationId: Scalars['ID']['input'];
}>;


export type CategoriesFromProductsQuery = { __typename?: 'Query', categoriesFromProducts: Array<{ __typename?: 'CategoriesFromProductsResult', id: string, name: string, meta?: { __typename?: 'CategoryMeta', icon?: string | null } | null } | null> };

export type CityListQueryVariables = Exact<{
  input?: InputMaybe<CityListInput>;
}>;


export type CityListQuery = { __typename?: 'Query', cityList?: Array<{ __typename?: 'DiscoveryCity', id: string, name: string, lat: number, lng: number, distance?: number | null } | null> | null };

export type LocationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  includeProducts?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type LocationQuery = { __typename?: 'Query', location?: { __typename?: 'Location', id: string, placeId?: string | null, name?: string | null, address?: string | null, hasOwner?: boolean | null, longitude?: number | null, latitude?: number | null, flags?: any | null, openingHours?: any | null, isFavourite?: boolean | null, images?: Array<{ __typename?: 'Image', url?: string | null, size?: string | null, blurhash?: string | null } | null> | null } | null };

export type OfferFromLocationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type OfferFromLocationQuery = { __typename?: 'Query', offer?: { __typename?: 'Offer', id: string, product?: { __typename?: 'Product', name: string, description?: string | null, images?: Array<{ __typename?: 'Image', url?: string | null, isPrimary?: boolean | null, blurhash?: string | null, size?: string | null } | null> | null, brand?: { __typename?: 'Brand', id: string, name?: string | null } | null, category?: { __typename?: 'Category', id?: string | null, name?: string | null } | null } | null, lastPrice?: { __typename?: 'Price', value?: number | null, date?: string | null, currency?: string | null } | null, variants?: Array<{ __typename?: 'OfferVariantGroup', id: string, type: string, items?: Array<{ __typename?: 'OfferVariant', id: string, variant: string } | null> | null } | null> | null } | null };

export type OffersShowcaseQueryVariables = Exact<{
  locationId: Scalars['ID']['input'];
}>;


export type OffersShowcaseQuery = { __typename?: 'Query', offersShowcase: Array<{ __typename?: 'ShowcaseCategory', id: string, name: string, order?: number | null, targetCategoryId?: string | null, offers: Array<{ __typename?: 'Offer', id: string, product?: { __typename?: 'Product', name: string, images?: Array<{ __typename?: 'Image', id?: string | null, url?: string | null, blurhash?: string | null, size?: string | null } | null> | null } | null, lastPrice?: { __typename?: 'Price', value?: number | null, date?: string | null, currency?: string | null } | null } | null> } | null> };

export type OffertsFromCategoryQueryVariables = Exact<{
  input: ProductsFromCategoryInput;
}>;


export type OffertsFromCategoryQuery = { __typename?: 'Query', productsFromCategory: { __typename?: 'PaginatedOfferts', data?: Array<{ __typename?: 'Offer', id: string, product?: { __typename?: 'Product', name: string, images?: Array<{ __typename?: 'Image', url?: string | null } | null> | null } | null, lastPrice?: { __typename?: 'Price', value?: number | null, currency?: string | null, date?: string | null } | null } | null> | null } };

export type ProductsByLocationIdQueryVariables = Exact<{
  locationId: Scalars['ID']['input'];
}>;


export type ProductsByLocationIdQuery = { __typename?: 'Query', productsByLocationId: Array<{ __typename?: 'CategoryWithProducts', id: string, name: string, parentId?: string | null, parent?: { __typename?: 'Category', id?: string | null, name?: string | null } | null, products?: Array<{ __typename?: 'Product', id: string, name: string, images?: Array<{ __typename?: 'Image', isPrimary?: boolean | null, url?: string | null } | null> | null, lastPrice?: { __typename?: 'Price', value?: number | null, date?: string | null } | null } | null> | null }> };

export type MyOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type MyOrdersQuery = { __typename?: 'Query', myOrders?: Array<{ __typename?: 'Order', id: string, status: OrderStatus, createdAt?: string | null, location?: { __typename?: 'Location', name?: string | null } | null, items?: Array<{ __typename?: 'OrderItem', id: string } | null> | null } | null> | null };

export type OrderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type OrderQuery = { __typename?: 'Query', order?: { __typename?: 'Order', id: string, paymentStatus: PaymentStatus, status: OrderStatus, createdAt?: string | null, location?: { __typename?: 'Location', id: string, name?: string | null } | null, paymentMethod?: { __typename?: 'LocationPaymentMethod', id: string, name?: string | null, type?: LocationPaymentMethodType | null } | null, deliveryAddress?: { __typename?: 'UserAddress', address?: any | null } | null, packingOption?: { __typename?: 'PackingOption', name?: string | null, description?: string | null, packingPrice?: number | null, currency?: string | null } | null, trackDelivery?: { __typename?: 'TrackDelivery', estimatedDeliveryTime?: string | null, updatedAt?: string | null } | null, deliveryOption?: { __typename?: 'DeliveryOption', type: DeliveryType, description: string, deliveryPrice: number, currency: string } | null, items?: Array<{ __typename?: 'OrderItem', id: string, offer?: { __typename?: 'OrderItemOffer', offerId: string, price?: number | null, currency?: string | null, quantity?: number | null, product?: { __typename?: 'OrderItemOfferProduct', name?: string | null, ean?: string | null, primaryImage?: { __typename?: 'Image', url?: string | null } | null } | null } | null } | null> | null } | null };

export type AddUserBillAddressMutationVariables = Exact<{
  input: UserBillAddressInput;
}>;


export type AddUserBillAddressMutation = { __typename?: 'Mutation', addUserBillAddress: { __typename?: 'UserAddress', id: string } };

export type AddUserInvoiceAddressMutationVariables = Exact<{
  input: UserInvoiceAddressInput;
}>;


export type AddUserInvoiceAddressMutation = { __typename?: 'Mutation', addUserInvoiceAddress: { __typename?: 'UserAddress', id: string } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', phoneNumber?: string | null } };

export type MyAddressesQueryVariables = Exact<{
  type: AddressType;
}>;


export type MyAddressesQuery = { __typename?: 'Query', myAddresses: Array<{ __typename?: 'UserAddress', id: string, address?: any | null }> };

export type SendVerificationCodeMutationVariables = Exact<{
  phoneNumber: Scalars['String']['input'];
}>;


export type SendVerificationCodeMutation = { __typename?: 'Mutation', sendVerificationCode: boolean };

export type SetPhoneNumberMutationVariables = Exact<{
  input: SetPhoneNumberInput;
}>;


export type SetPhoneNumberMutation = { __typename?: 'Mutation', setPhoneNumber: boolean };

export type SetupIntentQueryVariables = Exact<{ [key: string]: never; }>;


export type SetupIntentQuery = { __typename?: 'Query', setupIntent: { __typename?: 'SetupIntent', setupIntent: string, ephemeralKey: string, customer: string } };

export type UpsertUserDeliveryAddressMutationVariables = Exact<{
  input: UpsertUserDeliveryAddressInput;
}>;


export type UpsertUserDeliveryAddressMutation = { __typename?: 'Mutation', upsertUserDeliveryAddress: { __typename?: 'UserAddressResponse', response: { __typename?: 'UserAddressResponseMetadata', status: ResponseStatus, message?: string | null }, data?: { __typename?: 'UserAddress', id: string } | null } };

export type StoriesQueryVariables = Exact<{
  input: StoriesInput;
}>;


export type StoriesQuery = { __typename?: 'Query', stories?: Array<{ __typename?: 'Ad', id: string, location?: { __typename?: 'Location', name?: string | null, logo?: string | null } | null, story?: { __typename?: 'Story', id: string, reels?: Array<{ __typename?: 'Reel', id: string, source: string, addons?: any | null, type: ReelType, createdAt: string } | null> | null } | null } | null> | null };


export const AddCartItemDocument = `
    mutation AddCartItem($input: AddCartItemInput!) {
  addCartItem(input: $input) {
    cartItemId
  }
}
    `;
export const useAddCartItemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddCartItemMutation, TError, AddCartItemMutationVariables, TContext>) =>
    useMutation<AddCartItemMutation, TError, AddCartItemMutationVariables, TContext>(
      ['AddCartItem'],
      useFetchData<AddCartItemMutation, AddCartItemMutationVariables>(AddCartItemDocument),
      options
    );
export const CalculateShoppingCartDocument = `
    query CalculateShoppingCart($shoppingCartId: ID!) {
  calculateShoppingCart(shoppingCartId: $shoppingCartId)
}
    `;
export const useCalculateShoppingCartQuery = <
      TData = CalculateShoppingCartQuery,
      TError = unknown
    >(
      variables: CalculateShoppingCartQueryVariables,
      options?: UseQueryOptions<CalculateShoppingCartQuery, TError, TData>
    ) =>
    useQuery<CalculateShoppingCartQuery, TError, TData>(
      ['CalculateShoppingCart', variables],
      useFetchData<CalculateShoppingCartQuery, CalculateShoppingCartQueryVariables>(CalculateShoppingCartDocument).bind(null, variables),
      options
    );
export const useInfiniteCalculateShoppingCartQuery = <
      TData = CalculateShoppingCartQuery,
      TError = unknown
    >(
      variables: CalculateShoppingCartQueryVariables,
      options?: UseInfiniteQueryOptions<CalculateShoppingCartQuery, TError, TData>
    ) =>{
    const query = useFetchData<CalculateShoppingCartQuery, CalculateShoppingCartQueryVariables>(CalculateShoppingCartDocument)
    return useInfiniteQuery<CalculateShoppingCartQuery, TError, TData>(
      ['CalculateShoppingCart.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const CompleteCartProcessDocument = `
    mutation CompleteCartProcess($input: CompleteCartProcessInput!) {
  completeCartProcess(input: $input) {
    data {
      orderId
    }
  }
}
    `;
export const useCompleteCartProcessMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CompleteCartProcessMutation, TError, CompleteCartProcessMutationVariables, TContext>) =>
    useMutation<CompleteCartProcessMutation, TError, CompleteCartProcessMutationVariables, TContext>(
      ['CompleteCartProcess'],
      useFetchData<CompleteCartProcessMutation, CompleteCartProcessMutationVariables>(CompleteCartProcessDocument),
      options
    );
export const CreatePaymentIntentDocument = `
    mutation CreatePaymentIntent($input: CreatePaymentIntentInput!) {
  createPaymentIntent(input: $input) {
    paymentIntent
    ephemeralKey
    customer
  }
}
    `;
export const useCreatePaymentIntentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreatePaymentIntentMutation, TError, CreatePaymentIntentMutationVariables, TContext>) =>
    useMutation<CreatePaymentIntentMutation, TError, CreatePaymentIntentMutationVariables, TContext>(
      ['CreatePaymentIntent'],
      useFetchData<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>(CreatePaymentIntentDocument),
      options
    );
export const DecreaseCartItemDocument = `
    mutation DecreaseCartItem($input: AddCartItemInput!) {
  decreaseCartItem(input: $input)
}
    `;
export const useDecreaseCartItemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DecreaseCartItemMutation, TError, DecreaseCartItemMutationVariables, TContext>) =>
    useMutation<DecreaseCartItemMutation, TError, DecreaseCartItemMutationVariables, TContext>(
      ['DecreaseCartItem'],
      useFetchData<DecreaseCartItemMutation, DecreaseCartItemMutationVariables>(DecreaseCartItemDocument),
      options
    );
export const IncreaseCartItemDocument = `
    mutation IncreaseCartItem($input: AddCartItemInput!) {
  increaseCartItem(input: $input)
}
    `;
export const useIncreaseCartItemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<IncreaseCartItemMutation, TError, IncreaseCartItemMutationVariables, TContext>) =>
    useMutation<IncreaseCartItemMutation, TError, IncreaseCartItemMutationVariables, TContext>(
      ['IncreaseCartItem'],
      useFetchData<IncreaseCartItemMutation, IncreaseCartItemMutationVariables>(IncreaseCartItemDocument),
      options
    );
export const LocationDeliveryOptionsDocument = `
    query LocationDeliveryOptions($locationId: ID!) {
  locationDeliveryOptions(locationId: $locationId) {
    id
    type
    description
    deliveryPrice
    currency
    radius
  }
}
    `;
export const useLocationDeliveryOptionsQuery = <
      TData = LocationDeliveryOptionsQuery,
      TError = unknown
    >(
      variables: LocationDeliveryOptionsQueryVariables,
      options?: UseQueryOptions<LocationDeliveryOptionsQuery, TError, TData>
    ) =>
    useQuery<LocationDeliveryOptionsQuery, TError, TData>(
      ['LocationDeliveryOptions', variables],
      useFetchData<LocationDeliveryOptionsQuery, LocationDeliveryOptionsQueryVariables>(LocationDeliveryOptionsDocument).bind(null, variables),
      options
    );
export const useInfiniteLocationDeliveryOptionsQuery = <
      TData = LocationDeliveryOptionsQuery,
      TError = unknown
    >(
      variables: LocationDeliveryOptionsQueryVariables,
      options?: UseInfiniteQueryOptions<LocationDeliveryOptionsQuery, TError, TData>
    ) =>{
    const query = useFetchData<LocationDeliveryOptionsQuery, LocationDeliveryOptionsQueryVariables>(LocationDeliveryOptionsDocument)
    return useInfiniteQuery<LocationDeliveryOptionsQuery, TError, TData>(
      ['LocationDeliveryOptions.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const LocationDeliveryPackingOptionsDocument = `
    query LocationDeliveryPackingOptions($locationId: ID!) {
  locationDeliveryPackingOptions(locationId: $locationId) {
    id
    name
    description
    packingPrice
  }
}
    `;
export const useLocationDeliveryPackingOptionsQuery = <
      TData = LocationDeliveryPackingOptionsQuery,
      TError = unknown
    >(
      variables: LocationDeliveryPackingOptionsQueryVariables,
      options?: UseQueryOptions<LocationDeliveryPackingOptionsQuery, TError, TData>
    ) =>
    useQuery<LocationDeliveryPackingOptionsQuery, TError, TData>(
      ['LocationDeliveryPackingOptions', variables],
      useFetchData<LocationDeliveryPackingOptionsQuery, LocationDeliveryPackingOptionsQueryVariables>(LocationDeliveryPackingOptionsDocument).bind(null, variables),
      options
    );
export const useInfiniteLocationDeliveryPackingOptionsQuery = <
      TData = LocationDeliveryPackingOptionsQuery,
      TError = unknown
    >(
      variables: LocationDeliveryPackingOptionsQueryVariables,
      options?: UseInfiniteQueryOptions<LocationDeliveryPackingOptionsQuery, TError, TData>
    ) =>{
    const query = useFetchData<LocationDeliveryPackingOptionsQuery, LocationDeliveryPackingOptionsQueryVariables>(LocationDeliveryPackingOptionsDocument)
    return useInfiniteQuery<LocationDeliveryPackingOptionsQuery, TError, TData>(
      ['LocationDeliveryPackingOptions.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const LocationPaymentMethodsDocument = `
    query LocationPaymentMethods($locationId: ID!) {
  locationPaymentMethods(locationId: $locationId) {
    id
    name
    type
    active
    locationId
  }
}
    `;
export const useLocationPaymentMethodsQuery = <
      TData = LocationPaymentMethodsQuery,
      TError = unknown
    >(
      variables: LocationPaymentMethodsQueryVariables,
      options?: UseQueryOptions<LocationPaymentMethodsQuery, TError, TData>
    ) =>
    useQuery<LocationPaymentMethodsQuery, TError, TData>(
      ['LocationPaymentMethods', variables],
      useFetchData<LocationPaymentMethodsQuery, LocationPaymentMethodsQueryVariables>(LocationPaymentMethodsDocument).bind(null, variables),
      options
    );
export const useInfiniteLocationPaymentMethodsQuery = <
      TData = LocationPaymentMethodsQuery,
      TError = unknown
    >(
      variables: LocationPaymentMethodsQueryVariables,
      options?: UseInfiniteQueryOptions<LocationPaymentMethodsQuery, TError, TData>
    ) =>{
    const query = useFetchData<LocationPaymentMethodsQuery, LocationPaymentMethodsQueryVariables>(LocationPaymentMethodsDocument)
    return useInfiniteQuery<LocationPaymentMethodsQuery, TError, TData>(
      ['LocationPaymentMethods.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const RelatedProductsByCategoryDocument = `
    query RelatedProductsByCategory($input: OffertsFromCategoryInput!) {
  offertsFromCategory(input: $input) {
    data {
      id
      product {
        images {
          url
        }
        name
      }
      id
      lastPrice {
        value
        date
      }
    }
  }
}
    `;
export const useRelatedProductsByCategoryQuery = <
      TData = RelatedProductsByCategoryQuery,
      TError = unknown
    >(
      variables: RelatedProductsByCategoryQueryVariables,
      options?: UseQueryOptions<RelatedProductsByCategoryQuery, TError, TData>
    ) =>
    useQuery<RelatedProductsByCategoryQuery, TError, TData>(
      ['RelatedProductsByCategory', variables],
      useFetchData<RelatedProductsByCategoryQuery, RelatedProductsByCategoryQueryVariables>(RelatedProductsByCategoryDocument).bind(null, variables),
      options
    );
export const useInfiniteRelatedProductsByCategoryQuery = <
      TData = RelatedProductsByCategoryQuery,
      TError = unknown
    >(
      variables: RelatedProductsByCategoryQueryVariables,
      options?: UseInfiniteQueryOptions<RelatedProductsByCategoryQuery, TError, TData>
    ) =>{
    const query = useFetchData<RelatedProductsByCategoryQuery, RelatedProductsByCategoryQueryVariables>(RelatedProductsByCategoryDocument)
    return useInfiniteQuery<RelatedProductsByCategoryQuery, TError, TData>(
      ['RelatedProductsByCategory.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const RemoveCartDocument = `
    mutation RemoveCart($cartId: ID!) {
  removeCart(cartId: $cartId)
}
    `;
export const useRemoveCartMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveCartMutation, TError, RemoveCartMutationVariables, TContext>) =>
    useMutation<RemoveCartMutation, TError, RemoveCartMutationVariables, TContext>(
      ['RemoveCart'],
      useFetchData<RemoveCartMutation, RemoveCartMutationVariables>(RemoveCartDocument),
      options
    );
export const RemoveCartItemDocument = `
    mutation RemoveCartItem($input: RemoveCartItemInput!) {
  removeCartItem(input: $input)
}
    `;
export const useRemoveCartItemMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveCartItemMutation, TError, RemoveCartItemMutationVariables, TContext>) =>
    useMutation<RemoveCartItemMutation, TError, RemoveCartItemMutationVariables, TContext>(
      ['RemoveCartItem'],
      useFetchData<RemoveCartItemMutation, RemoveCartItemMutationVariables>(RemoveCartItemDocument),
      options
    );
export const SetCartDeliveryAddressDocument = `
    mutation SetCartDeliveryAddress($input: SetCartDeliveryAddressInput!) {
  setCartDeliveryAddress(input: $input) {
    response {
      status
      message
    }
    data {
      id
    }
  }
}
    `;
export const useSetCartDeliveryAddressMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetCartDeliveryAddressMutation, TError, SetCartDeliveryAddressMutationVariables, TContext>) =>
    useMutation<SetCartDeliveryAddressMutation, TError, SetCartDeliveryAddressMutationVariables, TContext>(
      ['SetCartDeliveryAddress'],
      useFetchData<SetCartDeliveryAddressMutation, SetCartDeliveryAddressMutationVariables>(SetCartDeliveryAddressDocument),
      options
    );
export const SetCartGroupDeliveryOptionDocument = `
    mutation SetCartGroupDeliveryOption($input: SetCartGroupDeliveryOptionInput!) {
  setCartGroupDeliveryOption(input: $input)
}
    `;
export const useSetCartGroupDeliveryOptionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetCartGroupDeliveryOptionMutation, TError, SetCartGroupDeliveryOptionMutationVariables, TContext>) =>
    useMutation<SetCartGroupDeliveryOptionMutation, TError, SetCartGroupDeliveryOptionMutationVariables, TContext>(
      ['SetCartGroupDeliveryOption'],
      useFetchData<SetCartGroupDeliveryOptionMutation, SetCartGroupDeliveryOptionMutationVariables>(SetCartGroupDeliveryOptionDocument),
      options
    );
export const SetCartGroupDeliveryPackingOptionDocument = `
    mutation SetCartGroupDeliveryPackingOption($input: SetCartGroupDeliveryPackingOptionInput!) {
  setCartGroupDeliveryPackingOption(input: $input)
}
    `;
export const useSetCartGroupDeliveryPackingOptionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetCartGroupDeliveryPackingOptionMutation, TError, SetCartGroupDeliveryPackingOptionMutationVariables, TContext>) =>
    useMutation<SetCartGroupDeliveryPackingOptionMutation, TError, SetCartGroupDeliveryPackingOptionMutationVariables, TContext>(
      ['SetCartGroupDeliveryPackingOption'],
      useFetchData<SetCartGroupDeliveryPackingOptionMutation, SetCartGroupDeliveryPackingOptionMutationVariables>(SetCartGroupDeliveryPackingOptionDocument),
      options
    );
export const SetCartGroupPaymentMethodDocument = `
    mutation SetCartGroupPaymentMethod($input: SetCartGroupPaymentMethodInput!) {
  setCartGroupPaymentMethod(input: $input)
}
    `;
export const useSetCartGroupPaymentMethodMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetCartGroupPaymentMethodMutation, TError, SetCartGroupPaymentMethodMutationVariables, TContext>) =>
    useMutation<SetCartGroupPaymentMethodMutation, TError, SetCartGroupPaymentMethodMutationVariables, TContext>(
      ['SetCartGroupPaymentMethod'],
      useFetchData<SetCartGroupPaymentMethodMutation, SetCartGroupPaymentMethodMutationVariables>(SetCartGroupPaymentMethodDocument),
      options
    );
export const SetCartItemNoteDocument = `
    mutation SetCartItemNote($cartItemId: ID!, $note: String!) {
  setCartItemNote(cartItemId: $cartItemId, note: $note)
}
    `;
export const useSetCartItemNoteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetCartItemNoteMutation, TError, SetCartItemNoteMutationVariables, TContext>) =>
    useMutation<SetCartItemNoteMutation, TError, SetCartItemNoteMutationVariables, TContext>(
      ['SetCartItemNote'],
      useFetchData<SetCartItemNoteMutation, SetCartItemNoteMutationVariables>(SetCartItemNoteDocument),
      options
    );
export const SetCartItemQuantityDocument = `
    mutation SetCartItemQuantity($input: SetCartItemQuantityInput!) {
  setCartItemQuantity(input: $input) {
    data {
      cartItemId
    }
    error
  }
}
    `;
export const useSetCartItemQuantityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetCartItemQuantityMutation, TError, SetCartItemQuantityMutationVariables, TContext>) =>
    useMutation<SetCartItemQuantityMutation, TError, SetCartItemQuantityMutationVariables, TContext>(
      ['SetCartItemQuantity'],
      useFetchData<SetCartItemQuantityMutation, SetCartItemQuantityMutationVariables>(SetCartItemQuantityDocument),
      options
    );
export const SetCartItemReplacementDocument = `
    mutation SetCartItemReplacement($input: SetCartItemReplacementInput!) {
  setCartItemReplacement(input: $input)
}
    `;
export const useSetCartItemReplacementMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetCartItemReplacementMutation, TError, SetCartItemReplacementMutationVariables, TContext>) =>
    useMutation<SetCartItemReplacementMutation, TError, SetCartItemReplacementMutationVariables, TContext>(
      ['SetCartItemReplacement'],
      useFetchData<SetCartItemReplacementMutation, SetCartItemReplacementMutationVariables>(SetCartItemReplacementDocument),
      options
    );
export const ShopLocationDocument = `
    query ShopLocation($id: ID!, $includeProducts: Boolean) {
  location(id: $id, includeProducts: $includeProducts) {
    longitude
    latitude
  }
}
    `;
export const useShopLocationQuery = <
      TData = ShopLocationQuery,
      TError = unknown
    >(
      variables: ShopLocationQueryVariables,
      options?: UseQueryOptions<ShopLocationQuery, TError, TData>
    ) =>
    useQuery<ShopLocationQuery, TError, TData>(
      ['ShopLocation', variables],
      useFetchData<ShopLocationQuery, ShopLocationQueryVariables>(ShopLocationDocument).bind(null, variables),
      options
    );
export const useInfiniteShopLocationQuery = <
      TData = ShopLocationQuery,
      TError = unknown
    >(
      variables: ShopLocationQueryVariables,
      options?: UseInfiniteQueryOptions<ShopLocationQuery, TError, TData>
    ) =>{
    const query = useFetchData<ShopLocationQuery, ShopLocationQueryVariables>(ShopLocationDocument)
    return useInfiniteQuery<ShopLocationQuery, TError, TData>(
      ['ShopLocation.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const ShoppingCartsDocument = `
    query ShoppingCarts {
  shoppingCarts {
    id
    stripeIntentClientSecret
    customerId
    deliveryOption {
      id
      type
      description
      deliveryPrice
    }
    deliveryOptionCopy {
      type
      description
      deliveryPrice
    }
    deliveryPackingOption {
      id
      name
      description
      packingPrice
    }
    deliveryAddressCopy {
      id
      address
    }
    deliveryPackingOptionCopy {
      id
      name
      description
      packingPrice
    }
    payment {
      metadata {
        paymentIntentId
        ephemeralKeySecret
        customerId
        paymentIntentClientSecret
      }
    }
    paymentMethodId
    paymentMethod {
      type
    }
    location {
      id
      name
      images {
        id
        url
        size
      }
    }
    items {
      id
      createdAt
      price
      quantity
      note
      replacement {
        id
      }
      offer {
        id
        product {
          primaryImage {
            url
          }
          name
          categoryId
        }
      }
    }
  }
}
    `;
export const useShoppingCartsQuery = <
      TData = ShoppingCartsQuery,
      TError = unknown
    >(
      variables?: ShoppingCartsQueryVariables,
      options?: UseQueryOptions<ShoppingCartsQuery, TError, TData>
    ) =>
    useQuery<ShoppingCartsQuery, TError, TData>(
      variables === undefined ? ['ShoppingCarts'] : ['ShoppingCarts', variables],
      useFetchData<ShoppingCartsQuery, ShoppingCartsQueryVariables>(ShoppingCartsDocument).bind(null, variables),
      options
    );
export const useInfiniteShoppingCartsQuery = <
      TData = ShoppingCartsQuery,
      TError = unknown
    >(
      variables?: ShoppingCartsQueryVariables,
      options?: UseInfiniteQueryOptions<ShoppingCartsQuery, TError, TData>
    ) =>{
    const query = useFetchData<ShoppingCartsQuery, ShoppingCartsQueryVariables>(ShoppingCartsDocument)
    return useInfiniteQuery<ShoppingCartsQuery, TError, TData>(
      variables === undefined ? ['ShoppingCarts.infinite'] : ['ShoppingCarts.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const DiscoveryLocationDocument = `
    query DiscoveryLocation($input: DiscoveryLocationInput!) {
  discoveryLocation(input: $input) {
    id
    name
    latitude
    longitude
    categories {
      id
      name
      locations {
        id
        name
        images(size: ["logo"]) {
          id
          url
          blurhash
          size
        }
        distance
      }
    }
  }
}
    `;
export const useDiscoveryLocationQuery = <
      TData = DiscoveryLocationQuery,
      TError = unknown
    >(
      variables: DiscoveryLocationQueryVariables,
      options?: UseQueryOptions<DiscoveryLocationQuery, TError, TData>
    ) =>
    useQuery<DiscoveryLocationQuery, TError, TData>(
      ['DiscoveryLocation', variables],
      useFetchData<DiscoveryLocationQuery, DiscoveryLocationQueryVariables>(DiscoveryLocationDocument).bind(null, variables),
      options
    );
export const useInfiniteDiscoveryLocationQuery = <
      TData = DiscoveryLocationQuery,
      TError = unknown
    >(
      variables: DiscoveryLocationQueryVariables,
      options?: UseInfiniteQueryOptions<DiscoveryLocationQuery, TError, TData>
    ) =>{
    const query = useFetchData<DiscoveryLocationQuery, DiscoveryLocationQueryVariables>(DiscoveryLocationDocument)
    return useInfiniteQuery<DiscoveryLocationQuery, TError, TData>(
      ['DiscoveryLocation.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const ListNearOffersDocument = `
    query ListNearOffers($input: ListOffersInput!) {
  listNearOffers(input: $input) {
    location {
      id
      name
      address
      images(size: ["logo"]) {
        url
      }
    }
    product {
      id
      name
      category {
        id
      }
      images {
        url
      }
    }
    offer {
      id
    }
    distance
  }
}
    `;
export const useListNearOffersQuery = <
      TData = ListNearOffersQuery,
      TError = unknown
    >(
      variables: ListNearOffersQueryVariables,
      options?: UseQueryOptions<ListNearOffersQuery, TError, TData>
    ) =>
    useQuery<ListNearOffersQuery, TError, TData>(
      ['ListNearOffers', variables],
      useFetchData<ListNearOffersQuery, ListNearOffersQueryVariables>(ListNearOffersDocument).bind(null, variables),
      options
    );
export const useInfiniteListNearOffersQuery = <
      TData = ListNearOffersQuery,
      TError = unknown
    >(
      variables: ListNearOffersQueryVariables,
      options?: UseInfiniteQueryOptions<ListNearOffersQuery, TError, TData>
    ) =>{
    const query = useFetchData<ListNearOffersQuery, ListNearOffersQueryVariables>(ListNearOffersDocument)
    return useInfiniteQuery<ListNearOffersQuery, TError, TData>(
      ['ListNearOffers.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const ListOffersByCategoryDocument = `
    query ListOffersByCategory($input: ListOffersByCategoryInput!) {
  listOffersByCategory(input: $input) {
    id
    product {
      name
      images {
        url
      }
      category {
        name
      }
    }
  }
}
    `;
export const useListOffersByCategoryQuery = <
      TData = ListOffersByCategoryQuery,
      TError = unknown
    >(
      variables: ListOffersByCategoryQueryVariables,
      options?: UseQueryOptions<ListOffersByCategoryQuery, TError, TData>
    ) =>
    useQuery<ListOffersByCategoryQuery, TError, TData>(
      ['ListOffersByCategory', variables],
      useFetchData<ListOffersByCategoryQuery, ListOffersByCategoryQueryVariables>(ListOffersByCategoryDocument).bind(null, variables),
      options
    );
export const useInfiniteListOffersByCategoryQuery = <
      TData = ListOffersByCategoryQuery,
      TError = unknown
    >(
      variables: ListOffersByCategoryQueryVariables,
      options?: UseInfiniteQueryOptions<ListOffersByCategoryQuery, TError, TData>
    ) =>{
    const query = useFetchData<ListOffersByCategoryQuery, ListOffersByCategoryQueryVariables>(ListOffersByCategoryDocument)
    return useInfiniteQuery<ListOffersByCategoryQuery, TError, TData>(
      ['ListOffersByCategory.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const SearchDocument = `
    query Search($input: SearchInput!) {
  search(input: $input) {
    results {
      id
      name
      image
      categoryId
      offerCount
    }
    page
    total
  }
}
    `;
export const useSearchQuery = <
      TData = SearchQuery,
      TError = unknown
    >(
      variables: SearchQueryVariables,
      options?: UseQueryOptions<SearchQuery, TError, TData>
    ) =>
    useQuery<SearchQuery, TError, TData>(
      ['Search', variables],
      useFetchData<SearchQuery, SearchQueryVariables>(SearchDocument).bind(null, variables),
      options
    );
export const useInfiniteSearchQuery = <
      TData = SearchQuery,
      TError = unknown
    >(
      variables: SearchQueryVariables,
      options?: UseInfiniteQueryOptions<SearchQuery, TError, TData>
    ) =>{
    const query = useFetchData<SearchQuery, SearchQueryVariables>(SearchDocument)
    return useInfiniteQuery<SearchQuery, TError, TData>(
      ['Search.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const BrandsFromCategoryDocument = `
    query BrandsFromCategory($input: BrandsFromCategoryInput!) {
  brandsFromCategory(input: $input) {
    id
    name
  }
}
    `;
export const useBrandsFromCategoryQuery = <
      TData = BrandsFromCategoryQuery,
      TError = unknown
    >(
      variables: BrandsFromCategoryQueryVariables,
      options?: UseQueryOptions<BrandsFromCategoryQuery, TError, TData>
    ) =>
    useQuery<BrandsFromCategoryQuery, TError, TData>(
      ['BrandsFromCategory', variables],
      useFetchData<BrandsFromCategoryQuery, BrandsFromCategoryQueryVariables>(BrandsFromCategoryDocument).bind(null, variables),
      options
    );
export const useInfiniteBrandsFromCategoryQuery = <
      TData = BrandsFromCategoryQuery,
      TError = unknown
    >(
      variables: BrandsFromCategoryQueryVariables,
      options?: UseInfiniteQueryOptions<BrandsFromCategoryQuery, TError, TData>
    ) =>{
    const query = useFetchData<BrandsFromCategoryQuery, BrandsFromCategoryQueryVariables>(BrandsFromCategoryDocument)
    return useInfiniteQuery<BrandsFromCategoryQuery, TError, TData>(
      ['BrandsFromCategory.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const CategoriesFromParentDocument = `
    query CategoriesFromParent($input: CategoriesFromParentInput!) {
  categoriesFromParent(input: $input) {
    id
    name
  }
}
    `;
export const useCategoriesFromParentQuery = <
      TData = CategoriesFromParentQuery,
      TError = unknown
    >(
      variables: CategoriesFromParentQueryVariables,
      options?: UseQueryOptions<CategoriesFromParentQuery, TError, TData>
    ) =>
    useQuery<CategoriesFromParentQuery, TError, TData>(
      ['CategoriesFromParent', variables],
      useFetchData<CategoriesFromParentQuery, CategoriesFromParentQueryVariables>(CategoriesFromParentDocument).bind(null, variables),
      options
    );
export const useInfiniteCategoriesFromParentQuery = <
      TData = CategoriesFromParentQuery,
      TError = unknown
    >(
      variables: CategoriesFromParentQueryVariables,
      options?: UseInfiniteQueryOptions<CategoriesFromParentQuery, TError, TData>
    ) =>{
    const query = useFetchData<CategoriesFromParentQuery, CategoriesFromParentQueryVariables>(CategoriesFromParentDocument)
    return useInfiniteQuery<CategoriesFromParentQuery, TError, TData>(
      ['CategoriesFromParent.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const CategoriesFromProductsDocument = `
    query CategoriesFromProducts($locationId: ID!) {
  categoriesFromProducts(locationId: $locationId) {
    id
    name
    meta {
      icon
    }
  }
}
    `;
export const useCategoriesFromProductsQuery = <
      TData = CategoriesFromProductsQuery,
      TError = unknown
    >(
      variables: CategoriesFromProductsQueryVariables,
      options?: UseQueryOptions<CategoriesFromProductsQuery, TError, TData>
    ) =>
    useQuery<CategoriesFromProductsQuery, TError, TData>(
      ['CategoriesFromProducts', variables],
      useFetchData<CategoriesFromProductsQuery, CategoriesFromProductsQueryVariables>(CategoriesFromProductsDocument).bind(null, variables),
      options
    );
export const useInfiniteCategoriesFromProductsQuery = <
      TData = CategoriesFromProductsQuery,
      TError = unknown
    >(
      variables: CategoriesFromProductsQueryVariables,
      options?: UseInfiniteQueryOptions<CategoriesFromProductsQuery, TError, TData>
    ) =>{
    const query = useFetchData<CategoriesFromProductsQuery, CategoriesFromProductsQueryVariables>(CategoriesFromProductsDocument)
    return useInfiniteQuery<CategoriesFromProductsQuery, TError, TData>(
      ['CategoriesFromProducts.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const CityListDocument = `
    query CityList($input: CityListInput) {
  cityList(input: $input) {
    id
    name
    lat
    lng
    distance
  }
}
    `;
export const useCityListQuery = <
      TData = CityListQuery,
      TError = unknown
    >(
      variables?: CityListQueryVariables,
      options?: UseQueryOptions<CityListQuery, TError, TData>
    ) =>
    useQuery<CityListQuery, TError, TData>(
      variables === undefined ? ['CityList'] : ['CityList', variables],
      useFetchData<CityListQuery, CityListQueryVariables>(CityListDocument).bind(null, variables),
      options
    );
export const useInfiniteCityListQuery = <
      TData = CityListQuery,
      TError = unknown
    >(
      variables?: CityListQueryVariables,
      options?: UseInfiniteQueryOptions<CityListQuery, TError, TData>
    ) =>{
    const query = useFetchData<CityListQuery, CityListQueryVariables>(CityListDocument)
    return useInfiniteQuery<CityListQuery, TError, TData>(
      variables === undefined ? ['CityList.infinite'] : ['CityList.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const LocationDocument = `
    query Location($id: ID!, $includeProducts: Boolean) {
  location(id: $id, includeProducts: $includeProducts) {
    id
    placeId
    name
    address
    hasOwner
    longitude
    latitude
    flags
    images(size: ["cover", "logo"]) {
      url
      size
      blurhash
    }
    openingHours
    isFavourite
  }
}
    `;
export const useLocationQuery = <
      TData = LocationQuery,
      TError = unknown
    >(
      variables: LocationQueryVariables,
      options?: UseQueryOptions<LocationQuery, TError, TData>
    ) =>
    useQuery<LocationQuery, TError, TData>(
      ['Location', variables],
      useFetchData<LocationQuery, LocationQueryVariables>(LocationDocument).bind(null, variables),
      options
    );
export const useInfiniteLocationQuery = <
      TData = LocationQuery,
      TError = unknown
    >(
      variables: LocationQueryVariables,
      options?: UseInfiniteQueryOptions<LocationQuery, TError, TData>
    ) =>{
    const query = useFetchData<LocationQuery, LocationQueryVariables>(LocationDocument)
    return useInfiniteQuery<LocationQuery, TError, TData>(
      ['Location.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const OfferFromLocationDocument = `
    query OfferFromLocation($id: ID!) {
  offer(id: $id) {
    id
    product {
      name
      description
      images {
        url
        isPrimary
        blurhash
        size
      }
      brand {
        id
        name
      }
      category {
        id
        name
      }
    }
    lastPrice {
      value
      date
      currency
    }
    variants {
      id
      type
      items {
        id
        variant
      }
    }
  }
}
    `;
export const useOfferFromLocationQuery = <
      TData = OfferFromLocationQuery,
      TError = unknown
    >(
      variables: OfferFromLocationQueryVariables,
      options?: UseQueryOptions<OfferFromLocationQuery, TError, TData>
    ) =>
    useQuery<OfferFromLocationQuery, TError, TData>(
      ['OfferFromLocation', variables],
      useFetchData<OfferFromLocationQuery, OfferFromLocationQueryVariables>(OfferFromLocationDocument).bind(null, variables),
      options
    );
export const useInfiniteOfferFromLocationQuery = <
      TData = OfferFromLocationQuery,
      TError = unknown
    >(
      variables: OfferFromLocationQueryVariables,
      options?: UseInfiniteQueryOptions<OfferFromLocationQuery, TError, TData>
    ) =>{
    const query = useFetchData<OfferFromLocationQuery, OfferFromLocationQueryVariables>(OfferFromLocationDocument)
    return useInfiniteQuery<OfferFromLocationQuery, TError, TData>(
      ['OfferFromLocation.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const OffersShowcaseDocument = `
    query OffersShowcase($locationId: ID!) {
  offersShowcase(locationId: $locationId) {
    id
    name
    order
    targetCategoryId
    offers {
      id
      product {
        name
        images {
          id
          url
          blurhash
          size
        }
      }
      lastPrice {
        value
        date
        currency
      }
    }
  }
}
    `;
export const useOffersShowcaseQuery = <
      TData = OffersShowcaseQuery,
      TError = unknown
    >(
      variables: OffersShowcaseQueryVariables,
      options?: UseQueryOptions<OffersShowcaseQuery, TError, TData>
    ) =>
    useQuery<OffersShowcaseQuery, TError, TData>(
      ['OffersShowcase', variables],
      useFetchData<OffersShowcaseQuery, OffersShowcaseQueryVariables>(OffersShowcaseDocument).bind(null, variables),
      options
    );
export const useInfiniteOffersShowcaseQuery = <
      TData = OffersShowcaseQuery,
      TError = unknown
    >(
      variables: OffersShowcaseQueryVariables,
      options?: UseInfiniteQueryOptions<OffersShowcaseQuery, TError, TData>
    ) =>{
    const query = useFetchData<OffersShowcaseQuery, OffersShowcaseQueryVariables>(OffersShowcaseDocument)
    return useInfiniteQuery<OffersShowcaseQuery, TError, TData>(
      ['OffersShowcase.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const OffertsFromCategoryDocument = `
    query OffertsFromCategory($input: ProductsFromCategoryInput!) {
  productsFromCategory(input: $input) {
    data {
      id
      product {
        images {
          url
        }
        name
      }
      id
      lastPrice {
        value
        currency
        date
      }
    }
  }
}
    `;
export const useOffertsFromCategoryQuery = <
      TData = OffertsFromCategoryQuery,
      TError = unknown
    >(
      variables: OffertsFromCategoryQueryVariables,
      options?: UseQueryOptions<OffertsFromCategoryQuery, TError, TData>
    ) =>
    useQuery<OffertsFromCategoryQuery, TError, TData>(
      ['OffertsFromCategory', variables],
      useFetchData<OffertsFromCategoryQuery, OffertsFromCategoryQueryVariables>(OffertsFromCategoryDocument).bind(null, variables),
      options
    );
export const useInfiniteOffertsFromCategoryQuery = <
      TData = OffertsFromCategoryQuery,
      TError = unknown
    >(
      variables: OffertsFromCategoryQueryVariables,
      options?: UseInfiniteQueryOptions<OffertsFromCategoryQuery, TError, TData>
    ) =>{
    const query = useFetchData<OffertsFromCategoryQuery, OffertsFromCategoryQueryVariables>(OffertsFromCategoryDocument)
    return useInfiniteQuery<OffertsFromCategoryQuery, TError, TData>(
      ['OffertsFromCategory.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const ProductsByLocationIdDocument = `
    query ProductsByLocationId($locationId: ID!) {
  productsByLocationId(locationId: $locationId) {
    id
    name
    parentId
    parent {
      id
      name
    }
    products {
      id
      name
      images {
        isPrimary
        url
      }
      lastPrice(locationId: $locationId) {
        value
        date
      }
    }
  }
}
    `;
export const useProductsByLocationIdQuery = <
      TData = ProductsByLocationIdQuery,
      TError = unknown
    >(
      variables: ProductsByLocationIdQueryVariables,
      options?: UseQueryOptions<ProductsByLocationIdQuery, TError, TData>
    ) =>
    useQuery<ProductsByLocationIdQuery, TError, TData>(
      ['ProductsByLocationId', variables],
      useFetchData<ProductsByLocationIdQuery, ProductsByLocationIdQueryVariables>(ProductsByLocationIdDocument).bind(null, variables),
      options
    );
export const useInfiniteProductsByLocationIdQuery = <
      TData = ProductsByLocationIdQuery,
      TError = unknown
    >(
      variables: ProductsByLocationIdQueryVariables,
      options?: UseInfiniteQueryOptions<ProductsByLocationIdQuery, TError, TData>
    ) =>{
    const query = useFetchData<ProductsByLocationIdQuery, ProductsByLocationIdQueryVariables>(ProductsByLocationIdDocument)
    return useInfiniteQuery<ProductsByLocationIdQuery, TError, TData>(
      ['ProductsByLocationId.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const MyOrdersDocument = `
    query MyOrders {
  myOrders {
    id
    status
    location {
      name
    }
    items {
      id
    }
    createdAt
  }
}
    `;
export const useMyOrdersQuery = <
      TData = MyOrdersQuery,
      TError = unknown
    >(
      variables?: MyOrdersQueryVariables,
      options?: UseQueryOptions<MyOrdersQuery, TError, TData>
    ) =>
    useQuery<MyOrdersQuery, TError, TData>(
      variables === undefined ? ['MyOrders'] : ['MyOrders', variables],
      useFetchData<MyOrdersQuery, MyOrdersQueryVariables>(MyOrdersDocument).bind(null, variables),
      options
    );
export const useInfiniteMyOrdersQuery = <
      TData = MyOrdersQuery,
      TError = unknown
    >(
      variables?: MyOrdersQueryVariables,
      options?: UseInfiniteQueryOptions<MyOrdersQuery, TError, TData>
    ) =>{
    const query = useFetchData<MyOrdersQuery, MyOrdersQueryVariables>(MyOrdersDocument)
    return useInfiniteQuery<MyOrdersQuery, TError, TData>(
      variables === undefined ? ['MyOrders.infinite'] : ['MyOrders.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const OrderDocument = `
    query Order($id: ID!) {
  order(id: $id) {
    id
    location {
      id
      name
    }
    paymentMethod {
      id
      name
      type
    }
    deliveryAddress {
      address
    }
    packingOption {
      name
      description
      packingPrice
      currency
    }
    trackDelivery {
      estimatedDeliveryTime
      updatedAt
    }
    deliveryOption {
      type
      description
      deliveryPrice
      currency
    }
    items {
      id
      offer {
        offerId
        price
        currency
        quantity
        product {
          name
          ean
          primaryImage {
            url
          }
        }
      }
    }
    paymentStatus
    status
    createdAt
  }
}
    `;
export const useOrderQuery = <
      TData = OrderQuery,
      TError = unknown
    >(
      variables: OrderQueryVariables,
      options?: UseQueryOptions<OrderQuery, TError, TData>
    ) =>
    useQuery<OrderQuery, TError, TData>(
      ['Order', variables],
      useFetchData<OrderQuery, OrderQueryVariables>(OrderDocument).bind(null, variables),
      options
    );
export const useInfiniteOrderQuery = <
      TData = OrderQuery,
      TError = unknown
    >(
      variables: OrderQueryVariables,
      options?: UseInfiniteQueryOptions<OrderQuery, TError, TData>
    ) =>{
    const query = useFetchData<OrderQuery, OrderQueryVariables>(OrderDocument)
    return useInfiniteQuery<OrderQuery, TError, TData>(
      ['Order.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AddUserBillAddressDocument = `
    mutation AddUserBillAddress($input: UserBillAddressInput!) {
  addUserBillAddress(input: $input) {
    id
  }
}
    `;
export const useAddUserBillAddressMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddUserBillAddressMutation, TError, AddUserBillAddressMutationVariables, TContext>) =>
    useMutation<AddUserBillAddressMutation, TError, AddUserBillAddressMutationVariables, TContext>(
      ['AddUserBillAddress'],
      useFetchData<AddUserBillAddressMutation, AddUserBillAddressMutationVariables>(AddUserBillAddressDocument),
      options
    );
export const AddUserInvoiceAddressDocument = `
    mutation AddUserInvoiceAddress($input: UserInvoiceAddressInput!) {
  addUserInvoiceAddress(input: $input) {
    id
  }
}
    `;
export const useAddUserInvoiceAddressMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddUserInvoiceAddressMutation, TError, AddUserInvoiceAddressMutationVariables, TContext>) =>
    useMutation<AddUserInvoiceAddressMutation, TError, AddUserInvoiceAddressMutationVariables, TContext>(
      ['AddUserInvoiceAddress'],
      useFetchData<AddUserInvoiceAddressMutation, AddUserInvoiceAddressMutationVariables>(AddUserInvoiceAddressDocument),
      options
    );
export const MeDocument = `
    query Me {
  me {
    phoneNumber
  }
}
    `;
export const useMeQuery = <
      TData = MeQuery,
      TError = unknown
    >(
      variables?: MeQueryVariables,
      options?: UseQueryOptions<MeQuery, TError, TData>
    ) =>
    useQuery<MeQuery, TError, TData>(
      variables === undefined ? ['Me'] : ['Me', variables],
      useFetchData<MeQuery, MeQueryVariables>(MeDocument).bind(null, variables),
      options
    );
export const useInfiniteMeQuery = <
      TData = MeQuery,
      TError = unknown
    >(
      variables?: MeQueryVariables,
      options?: UseInfiniteQueryOptions<MeQuery, TError, TData>
    ) =>{
    const query = useFetchData<MeQuery, MeQueryVariables>(MeDocument)
    return useInfiniteQuery<MeQuery, TError, TData>(
      variables === undefined ? ['Me.infinite'] : ['Me.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const MyAddressesDocument = `
    query MyAddresses($type: AddressType!) {
  myAddresses(type: $type) {
    id
    address
  }
}
    `;
export const useMyAddressesQuery = <
      TData = MyAddressesQuery,
      TError = unknown
    >(
      variables: MyAddressesQueryVariables,
      options?: UseQueryOptions<MyAddressesQuery, TError, TData>
    ) =>
    useQuery<MyAddressesQuery, TError, TData>(
      ['MyAddresses', variables],
      useFetchData<MyAddressesQuery, MyAddressesQueryVariables>(MyAddressesDocument).bind(null, variables),
      options
    );
export const useInfiniteMyAddressesQuery = <
      TData = MyAddressesQuery,
      TError = unknown
    >(
      variables: MyAddressesQueryVariables,
      options?: UseInfiniteQueryOptions<MyAddressesQuery, TError, TData>
    ) =>{
    const query = useFetchData<MyAddressesQuery, MyAddressesQueryVariables>(MyAddressesDocument)
    return useInfiniteQuery<MyAddressesQuery, TError, TData>(
      ['MyAddresses.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const SendVerificationCodeDocument = `
    mutation SendVerificationCode($phoneNumber: String!) {
  sendVerificationCode(phoneNumber: $phoneNumber)
}
    `;
export const useSendVerificationCodeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SendVerificationCodeMutation, TError, SendVerificationCodeMutationVariables, TContext>) =>
    useMutation<SendVerificationCodeMutation, TError, SendVerificationCodeMutationVariables, TContext>(
      ['SendVerificationCode'],
      useFetchData<SendVerificationCodeMutation, SendVerificationCodeMutationVariables>(SendVerificationCodeDocument),
      options
    );
export const SetPhoneNumberDocument = `
    mutation SetPhoneNumber($input: SetPhoneNumberInput!) {
  setPhoneNumber(input: $input)
}
    `;
export const useSetPhoneNumberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetPhoneNumberMutation, TError, SetPhoneNumberMutationVariables, TContext>) =>
    useMutation<SetPhoneNumberMutation, TError, SetPhoneNumberMutationVariables, TContext>(
      ['SetPhoneNumber'],
      useFetchData<SetPhoneNumberMutation, SetPhoneNumberMutationVariables>(SetPhoneNumberDocument),
      options
    );
export const SetupIntentDocument = `
    query SetupIntent {
  setupIntent {
    setupIntent
    ephemeralKey
    customer
  }
}
    `;
export const useSetupIntentQuery = <
      TData = SetupIntentQuery,
      TError = unknown
    >(
      variables?: SetupIntentQueryVariables,
      options?: UseQueryOptions<SetupIntentQuery, TError, TData>
    ) =>
    useQuery<SetupIntentQuery, TError, TData>(
      variables === undefined ? ['SetupIntent'] : ['SetupIntent', variables],
      useFetchData<SetupIntentQuery, SetupIntentQueryVariables>(SetupIntentDocument).bind(null, variables),
      options
    );
export const useInfiniteSetupIntentQuery = <
      TData = SetupIntentQuery,
      TError = unknown
    >(
      variables?: SetupIntentQueryVariables,
      options?: UseInfiniteQueryOptions<SetupIntentQuery, TError, TData>
    ) =>{
    const query = useFetchData<SetupIntentQuery, SetupIntentQueryVariables>(SetupIntentDocument)
    return useInfiniteQuery<SetupIntentQuery, TError, TData>(
      variables === undefined ? ['SetupIntent.infinite'] : ['SetupIntent.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const UpsertUserDeliveryAddressDocument = `
    mutation UpsertUserDeliveryAddress($input: UpsertUserDeliveryAddressInput!) {
  upsertUserDeliveryAddress(input: $input) {
    response {
      status
      message
    }
    data {
      id
    }
  }
}
    `;
export const useUpsertUserDeliveryAddressMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpsertUserDeliveryAddressMutation, TError, UpsertUserDeliveryAddressMutationVariables, TContext>) =>
    useMutation<UpsertUserDeliveryAddressMutation, TError, UpsertUserDeliveryAddressMutationVariables, TContext>(
      ['UpsertUserDeliveryAddress'],
      useFetchData<UpsertUserDeliveryAddressMutation, UpsertUserDeliveryAddressMutationVariables>(UpsertUserDeliveryAddressDocument),
      options
    );
export const StoriesDocument = `
    query Stories($input: StoriesInput!) {
  stories(input: $input) {
    id
    location {
      name
      logo
    }
    story {
      id
      reels {
        id
        source
        addons
        type
        createdAt
      }
    }
  }
}
    `;
export const useStoriesQuery = <
      TData = StoriesQuery,
      TError = unknown
    >(
      variables: StoriesQueryVariables,
      options?: UseQueryOptions<StoriesQuery, TError, TData>
    ) =>
    useQuery<StoriesQuery, TError, TData>(
      ['Stories', variables],
      useFetchData<StoriesQuery, StoriesQueryVariables>(StoriesDocument).bind(null, variables),
      options
    );
export const useInfiniteStoriesQuery = <
      TData = StoriesQuery,
      TError = unknown
    >(
      variables: StoriesQueryVariables,
      options?: UseInfiniteQueryOptions<StoriesQuery, TError, TData>
    ) =>{
    const query = useFetchData<StoriesQuery, StoriesQueryVariables>(StoriesDocument)
    return useInfiniteQuery<StoriesQuery, TError, TData>(
      ['Stories.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export type BigInt = Scalars["BigInt"];
export type DateTime = Scalars["DateTime"];
export type File = Scalars["File"];
export type Json = Scalars["Json"];
export type Upload = Scalars["Upload"];