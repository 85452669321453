import {
  IonCol,
  IonContent,
  IonGrid,
  IonRefresher,
  IonRefresherContent,
  IonRouterLink,
  IonRow,
  IonText,
  RefresherEventDetail,
  useIonViewWillEnter,
} from "@ionic/react";
import { Flex, Group, Skeleton } from "@mantine/core";
import { Money } from "@zozia/money";
import * as React from "react";
import { useParams } from "react-router-dom";

import { AppPage } from "../../../components/AppPage";
import { Price } from "../../../components/Price";
import { ProductImageCarousel } from "../../../components/ProductImageCarousel";
import { useCart } from "../../cart/CartContext";
import { useOfferFromLocationQuery } from "../../graphql/graphql";
import { ProductDetailsQuantityActions } from "./ProductDetailsQuantityActions";

export const ProductDetailPage = () => {
  const params = useParams<{
    locationId: string;
    productId: string;
  }>();

  const {
    data: offerQueryResult,
    isLoading: isLoadingProductQuery,
    refetch: refetchOffer,
  } = useOfferFromLocationQuery(
    {
      id: params.productId,
    },
    {
      select: (data) => {
        return {
          ...data.offer,
          lastPrice: Money.fromFractionlessAmount(
            data.offer.lastPrice.value,
            data.offer.lastPrice.currency as Uppercase<string>,
          ),
        };
      },
    },
  );
  const { getCartItem, addItem, decreaseItem, updateLocation } = useCart();

  useIonViewWillEnter(() => {
    updateLocation(params.locationId);
  }, [params]);

  const cartItem = getCartItem(offerQueryResult?.id);

  const onIncrease = React.useCallback(
    (quantity) => {
      addItem({
        offerId: offerQueryResult.id,
        locationId: params.locationId,
        quantity,
      });
    },
    [offerQueryResult, params],
  );

  const onDecrease = React.useCallback(() => {
    decreaseItem({
      offerId: offerQueryResult.id,
      locationId: params.locationId,
    });
  }, [offerQueryResult, params]);

  return (
    <AppPage
      title={offerQueryResult && `Produkt ${offerQueryResult.product.name}`}
    >
      <IonContent fullscreen>
        <IonRefresher
          slot="fixed"
          onIonRefresh={async (event: CustomEvent<RefresherEventDetail>) => {
            await refetchOffer();
            event.detail.complete();
          }}
        >
          <IonRefresherContent
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing..."
          />
        </IonRefresher>
        {isLoadingProductQuery ? (
          <Skeleton style={{ height: 350 }} />
        ) : (
          <>
            <ProductImageCarousel items={offerQueryResult.product.images} />
            <div className="p-4">
              <IonText>
                <h1 className="text-3xl">{offerQueryResult.product.name}</h1>
              </IonText>
              {offerQueryResult.lastPrice ? (
                <Flex justify="space-between" align="center">
                  <div className="py-2 text-4xl font-medium">
                    <Price value={offerQueryResult.lastPrice.toNumber()} />
                  </div>
                  <ProductDetailsQuantityActions
                    className="w-[65px] h-[34px]"
                    initialItemQuantity={cartItem?.quantity}
                    onIncrease={onIncrease}
                    onDecrease={onDecrease}
                  />
                </Flex>
              ) : null}
              {offerQueryResult.variants.length > 0 ? (
                <IonText className="font-bold">
                  Warianty{" "}
                  {offerQueryResult.variants
                    .map((variantGroup) => variantGroup.type)
                    .join("/")}
                </IonText>
              ) : null}
              <IonGrid className="text-s">
                <IonRow>
                  {offerQueryResult.variants
                    .flatMap((variantGroup) => variantGroup.items)
                    .map((variant) => (
                      <IonCol
                        size="4"
                        key={variant.id}
                        style={{ color: "#3b82f6" }}
                      >
                        <IonRouterLink
                          routerLink={`/tabs/discovery/locations/${params.locationId}/offer/${variant.id}`}
                          className="text-xs text-slate-800"
                        >
                          <div className="border-color-inherit rounded-lg border border-solid p-2 text-center">
                            {variant.variant}
                          </div>
                        </IonRouterLink>
                      </IonCol>
                    ))}
                </IonRow>
              </IonGrid>
              <div
                className="text-sm"
                dangerouslySetInnerHTML={{
                  __html: offerQueryResult.product.description,
                }}
              />
            </div>
          </>
        )}
      </IonContent>
    </AppPage>
  );
};
