import {
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonNavLink,
  IonToolbar,
} from "@ionic/react";
import {
  Button,
  Divider,
  Grid,
  Group,
  Loader,
  Skeleton,
  Stack,
  Text,
} from "@mantine/core";
import {
  IconArrowLeft,
  IconEdit,
  IconReplace,
  IconShoppingCart,
  IconTrash,
} from "@tabler/icons-react";
import { Money } from "@zozia/money";
import { Price, cropImageTo } from "@zozia/ui";
import * as React from "react";

import { AutoAlignImage } from "../../../../components/AutoAlignImage";
import { formatOrdinals } from "../../../../lib/translateCartText";
import { useCart } from "../../CartContext";
import { ActiveCartCheckoutPage } from "./ActiveCartCheckoutPage";
import css from "./ActiveCartPage.module.css";
import { AvatarGroup } from "./AvatarGroup";
import { ProductQuantityActions } from "./ProductQuantityActions";
import { RemoveCartButton } from "./RemoveCartButton";
import { useIsActiveCartCalculating } from "./useIsActiveCartCalculating";

const cropImageToDesiredSize = cropImageTo(38);

type ActiveCartPageProps = {
  forwardRef: React.RefObject<HTMLIonModalElement>;
  modalInstructionRef: React.RefObject<HTMLIonModalElement>;
  setSelectedItem: React.Dispatch<React.SetStateAction<any>>;
  activeCartsModalRef: React.RefObject<HTMLIonModalElement>;
  offerModalRef: React.RefObject<HTMLIonModalElement>;
  modalDeliveryAddressRef: React.RefObject<HTMLIonModalElement>;
};

export const ActiveCartPage = ({
  forwardRef,
  modalInstructionRef,
  activeCartsModalRef,
  modalDeliveryAddressRef,
  setSelectedItem,
  offerModalRef,
}: ActiveCartPageProps) => {
  const {
    cart,
    totalSum,
    carts,
    addItem,
    removeItem,
    increaseItem,
    decreaseItem,
    removeCart,
  } = useCart();
  const isActiveCartCalculating = useIsActiveCartCalculating({
    cartId: cart?.id,
  });
  const [currentItem, setCurrentItem] = React.useState<any>(null);
  const hasMoreThenThreeCarts = carts.length > 2;

  return (
    <>
      <IonHeader>
        <IonToolbar
          style={{
            paddingLeft: "0",
            paddingRight: "0",
          }}
        >
          <IonButtons slot="start">
            <Button
              size="compact-xs"
              color="black"
              variant="transparent"
              onClick={() => forwardRef.current.dismiss()}
            >
              <IconArrowLeft />
            </Button>
          </IonButtons>
          <Group align="center" justify="center" className="mr-[42px]">
            <Group className="relative">
              <AvatarGroup />
            </Group>

            {hasMoreThenThreeCarts ? (
              <Stack
                gap={0}
                align="center"
                onClick={() => {
                  activeCartsModalRef.current.present();
                }}
              >
                <IconShoppingCart size={20} />
                <Text style={{ fontSize: ".5rem" }}>
                  +{formatOrdinals(carts.length - 2)}
                </Text>
              </Stack>
            ) : null}
          </Group>
          <Divider className="w-full absolute left-0" />
        </IonToolbar>
      </IonHeader>
      <IonContent className={css.content}>
        <Group className="px-3" grow>
          <Group justify="space-between" className="py-3">
            <Text>{cart.location?.name}</Text>
            <Skeleton
              visible={isActiveCartCalculating}
              animate
              style={{ width: "auto" }}
            >
              <Price value={totalSum} />
            </Skeleton>
          </Group>
        </Group>
        <Divider className="my-3" />
        <Stack>
          {cart.items
            ?.sort((a, b) => a.createdAt - b.createdAt)
            .map((item) => {
              return (
                <Grid
                  columns={24}
                  key={item.id}
                  gutter={6}
                  className="mx-3 overflow-visible"
                >
                  <Grid.Col
                    span="content"
                    onClick={async () => {
                      setSelectedItem(item);

                      await offerModalRef.current.present();
                    }}
                  >
                    <AutoAlignImage
                      src={
                        item.offer.product?.primaryImage?.url
                          ? cropImageToDesiredSize(
                              item.offer.product?.primaryImage?.url,
                            )
                          : `https://picsum.photos/38/38?random=${Math.random()}`
                      }
                      style={{ width: 38, height: 38 }}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Stack gap={5}>
                      <Stack
                        gap={0}
                        onClick={async () => {
                          setSelectedItem(item);

                          await offerModalRef.current.present();
                        }}
                      >
                        <Text size="sm" className="line-clamp-2">
                          {item.offer.product.name}
                        </Text>
                        {/* <Text size="xs" c="gray">
                            Tekst dodatkowy
                          </Text> */}
                        {item.note ? (
                          <Text
                            size="xs"
                            c="gray"
                            className="flex items-center gap-1"
                          >
                            Notatka:
                            <span className="line-clamp-1">{item.note}</span>
                          </Text>
                        ) : null}
                        {item?.replacement?.id ? (
                          <Text
                            size="xs"
                            c="gray"
                            className="flex items-center gap-1"
                          >
                            <IconReplace size="14" /> Zamiennik dodany
                          </Text>
                        ) : null}
                      </Stack>
                      <Group>
                        <Group
                          gap={1}
                          onClick={() => {
                            modalInstructionRef.current.present();
                            setSelectedItem(item);
                          }}
                        >
                          <IconEdit size="13" />
                          <Text size="xs" component="span">
                            Dodaj instrukcje
                          </Text>
                        </Group>
                        <Group
                          gap={1}
                          justify="center"
                          onClick={() => {
                            removeItem(item.id);
                          }}
                        >
                          <IconTrash size="13" />
                          <Text size="xs" component="span">
                            Usuń
                          </Text>
                        </Group>
                      </Group>
                    </Stack>
                  </Grid.Col>
                  <Grid.Col
                    span="auto"
                    className="flex items-center justify-between flex-wrap"
                  >
                    <ProductQuantityActions
                      className="-mt-[34px]"
                      initialItemQuantity={item?.quantity}
                      onIncrease={(quantity) => {
                        setCurrentItem(item);
                        addItem({
                          offerId: item.offer.id,
                          locationId: cart.location.id,
                          quantity,
                        });
                      }}
                      onDecrease={() => {
                        setCurrentItem(item);
                        decreaseItem({
                          offerId: item.offer.id,
                          locationId: cart.location.id,
                        });
                      }}
                    />
                    <Skeleton
                      visible={
                        isActiveCartCalculating && currentItem?.id === item.id
                      }
                      animate
                      style={{ width: "auto" }}
                    >
                      <Price
                        as={Text}
                        size="sm"
                        component="span"
                        value={Money.fromFactionlessAndQuantity(
                          item.price,
                          item.quantity,
                          "PLN",
                        )}
                      />
                    </Skeleton>
                  </Grid.Col>
                </Grid>
              );
            })}
          <RemoveCartButton onSuccess={removeCart} />
        </Stack>
      </IonContent>
      <IonFooter>
        <div className="flex flex-col px-4 pt-4 pb-8 justify-center bg-white">
          <IonNavLink
            routerDirection="forward"
            component={() => (
              <ActiveCartCheckoutPage
                forwardRef={forwardRef}
                modalDeliveryAddressRef={modalDeliveryAddressRef}
              />
            )}
          >
            <Button
              radius="xl"
              size="md"
              fullWidth
              classNames={{
                root: "p-0",
              }}
            >
              {isActiveCartCalculating ? (
                <Loader color="white" type="dots" />
              ) : (
                "Przejdź do podsumowania"
              )}
            </Button>
          </IonNavLink>
        </div>
      </IonFooter>
    </>
  );
};
