import { useIonRouter } from "@ionic/react";
import { Text, Title } from "@mantine/core";
import { Money } from "@zozia/money";
import { Price, cropImageTo } from "@zozia/ui";
import { useIntersectionObserver } from "@zozia/ui";
import * as React from "react";
import { Link, useParams } from "react-router-dom";

import { AutoAlignImage } from "../../../../components/AutoAlignImage";
import { Snap } from "../../../../components/Snap";
import { SnapItem3 } from "../../../../components/SnapItem";
import { OffersShowcaseQuery } from "../../../graphql/graphql";
import { productDetailUrlTemplate } from "../../helpers/paths";

type ProductCardList = {
  items: OffersShowcaseQuery["offersShowcase"];
  match: any;
  contentRef: any;
};

export const ProductCardList = ({
  items,
  match,
  contentRef,
}: ProductCardList) => {
  const moveToId = React.useId();
  return (
    <div className="space-y-5">
      {items
        .sort((a, b) => a.order - b.order)
        .map((category) => (
          <div key={category.id}>
            {category.targetCategoryId ? (
              <Link to={`${match.url}/categories/${category.targetCategoryId}`}>
                <Title className="px-4 pb-2 font-extrabold">
                  {category.name}
                </Title>
              </Link>
            ) : (
              <Title className="px-4 pb-2 font-extrabold">
                {category.name}
              </Title>
            )}
            <Snap className="mx-1 gap-1">
              {category?.offers
                ?.map((offer) => (
                  <SnapItem3 key={offer.id} height="inherit" width="half">
                    <OptimizedOfferCard offer={offer} root={contentRef} />
                  </SnapItem3>
                ))
                .concat(
                  category.targetCategoryId ? (
                    <SnapItem3 key={moveToId} height="inherit" width="half">
                      <Link
                        to={`${match.url}/categories/${category.targetCategoryId}`}
                      >
                        <div className="flex h-full items-center justify-center px-3 text-center">
                          Zobacz wszystkie
                        </div>
                      </Link>
                    </SnapItem3>
                  ) : null,
                )
                .filter(Boolean)}
            </Snap>
          </div>
        ))}
    </div>
  );
};

const cropImageToDesiredSize = cropImageTo(115);

type OptimizedOfferCardProps = {
  offer: OffersShowcaseQuery["offersShowcase"][number]["offers"][number];
  root?: string;
  onClick?: () => void;
};

export const OptimizedOfferCard = ({
  offer,
  onClick,
  root,
}: OptimizedOfferCardProps) => {
  const { locationId } = useParams<{ locationId: string }>();
  const router = useIonRouter();
  const ref = React.useRef(null);

  const entry = useIntersectionObserver(ref, {
    root: root?.current,
    freezeOnceVisible: true,
  });

  return (
    <div
      ref={ref}
      key={offer.id}
      onClick={() => {
        if (onClick) {
          onClick();
          return;
        } else {
          router.push(
            productDetailUrlTemplate({
              productId: offer.id,
              locationId: locationId,
            }),
          );
        }
      }}
    >
      {!!entry?.isIntersecting ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <AutoAlignImage
            alt={offer.product.name}
            src={cropImageToDesiredSize(offer.product?.images?.[0]?.url)}
            blurhash={offer.product?.images?.[0]?.blurhash}
            style={{ width: 122, height: 120 }}
            className="object-scale-down"
          />
        </div>
      ) : null}
      <div className="px-3 pt-2">
        <Text className="line-clamp-4 min-h-[2rem]" size="xs">
          {offer.product?.name}
        </Text>
        <div className="pt-1 text-xs font-bold">
          <Price
            value={Money.fromFractionlessAmount(
              offer.lastPrice.value,
              offer.lastPrice.currency,
            ).toNumber()}
          />
          {/* {offer.lastPrice
            ? new Intl.NumberFormat("pl-PL", {
                style: "currency",
                currency: "PLN",
              }).format(offer.lastPrice.value)
            : "Brak ceny"} */}
        </div>
      </div>
    </div>
  );
};
