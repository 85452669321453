import {
  IonCol,
  IonContent,
  IonGrid,
  IonModal,
  IonRouterLink,
  IonRow,
  IonSkeletonText,
  IonText,
} from "@ionic/react";
import { Flex } from "@mantine/core";
import { Money } from "@zozia/money";
import { Price } from "@zozia/ui";
import * as React from "react";

import { ProductImageCarousel } from "../../../../components/ProductImageCarousel";
import { useOfferFromLocationQuery } from "../../../graphql/graphql";
import { ProductDetailsQuantityActions } from "../../../locations/pages/ProductDetailsQuantityActions";
import { useCart } from "../../CartContext";

type OfferModalProps = {
  forwardRef: React.RefObject<HTMLIonModalElement>;
  presentingElement?: HTMLElement;
  selectedItem?: any;
};

export const OfferModal = ({
  forwardRef,
  presentingElement,
  selectedItem,
}: OfferModalProps) => {
  const { data: offerQueryResult, isLoading: isLoadingProductQuery } =
    useOfferFromLocationQuery(
      {
        id: selectedItem?.offer?.id,
      },
      {
        enabled: !!selectedItem?.id,
        select: (data) => ({
          offer: {
            ...data.offer,
            lastPrice: Money.fromFractionlessAmount(
              data.offer.lastPrice.value,
              data.offer.lastPrice.currency as Uppercase<string>,
            ),
          },
        }),
      },
    );

  const { getCartItem, addItem, increaseItem, decreaseItem, cart } = useCart();

  const cartItem = getCartItem(offerQueryResult?.offer.id);

  console.log({ cart });
  return (
    <IonModal ref={forwardRef} presentingElement={presentingElement}>
      <IonContent fullscreen>
        {isLoadingProductQuery ? (
          <IonSkeletonText style={{ height: 200 }} animated />
        ) : (
          <>
            <ProductImageCarousel
              items={offerQueryResult.offer.product.images}
            />
            <div className="p-4">
              <IonText>
                <h1 className="text-3xl">
                  {offerQueryResult.offer.product.name}
                </h1>
              </IonText>
              {offerQueryResult.offer.lastPrice ? (
                <Flex align="center" justify="space-between">
                  <div className="py-2 text-4xl font-medium">
                    <Price
                      value={offerQueryResult.offer.lastPrice.toDecimal()}
                    />
                  </div>
                  <ProductDetailsQuantityActions
                    className="w-[65px] h-[34px]"
                    initialItemQuantity={cartItem?.quantity}
                    onIncrease={(quantity) => {
                      addItem({
                        offerId: offerQueryResult.offer.id,
                        locationId: cart.location.id,
                        quantity,
                      });
                    }}
                    onDecrease={() => {
                      decreaseItem({
                        offerId: offerQueryResult.offer.id,
                        locationId: cart.location.id,
                      });
                    }}
                  />
                </Flex>
              ) : null}
              {offerQueryResult.offer.variants.length > 0 ? (
                <IonText className="font-bold">
                  Warianty{" "}
                  {offerQueryResult.offer.variants
                    .map((variantGroup) => variantGroup.type)
                    .join("/")}
                </IonText>
              ) : null}
              <IonGrid className="text-s">
                <IonRow>
                  {offerQueryResult.offer.variants
                    .flatMap((variantGroup) => variantGroup.items)
                    .map((variant) => (
                      <IonCol
                        size="4"
                        key={variant.id}
                        style={{ color: "#3b82f6" }}
                      >
                        <IonRouterLink
                          routerLink={`/tabs/discovery/locations/${params.locationId}/offer/${variant.id}`}
                          className="text-xs text-slate-800"
                        >
                          <div className="border-color-inherit rounded-lg border border-solid p-2 text-center">
                            {variant.variant}
                          </div>
                        </IonRouterLink>
                      </IonCol>
                    ))}
                </IonRow>
              </IonGrid>
              <div
                className="text-sm"
                dangerouslySetInnerHTML={{
                  __html: offerQueryResult.offer.product.description,
                }}
              />
            </div>
          </>
        )}
      </IonContent>
    </IonModal>
  );
};
