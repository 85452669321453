import { ActionIcon } from "@mantine/core";
import { IconMinus, IconTrash } from "@tabler/icons-react";
import { noop } from "@zozia/fp";
import * as React from "react";

type DecreaseQuantityProps = {
  quantity: number;
  onDecrease: (event: any) => void;
};

export const DecreaseQuantity = ({
  quantity,
  onDecrease,
}: DecreaseQuantityProps) => {
  return quantity === 0 ? (
    <div></div>
  ) : (
    <ActionIcon
      size="xs"
      variant="transparent"
      c="black"
      onClick={quantity === 0 ? noop : onDecrease}
    >
      {quantity === 0 ? null : quantity > 1 ? <IconMinus /> : <IconTrash />}
    </ActionIcon>
  );
};
