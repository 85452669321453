import { IonContent, IonPage, useIonViewWillEnter } from "@ionic/react";
import { Button, Group, Stack, Title } from "@mantine/core";
import { Price } from "@zozia/ui";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { MobileSafeAnimatePresence } from "../../../components/MobileSafeAnimatePresence";
import { RouteBackModal } from "../../../components/RouteBackModal";
import { ModalAsRoute } from "../../discovery/components/ModalAsRoute";
import { Section } from "../../session/components/Section";
import { useCart } from "../CartContext";
import { ShoppingCartItem } from "../components/ShoppingCartItem";
import { CartDeliveryModalPage } from "./CartDeliveryModalPage";

export const CartPage = ({ prevMatch }) => {
  const match = useRouteMatch();
  const { carts, totalSum, fetchCart } = useCart();
  const history = useHistory();
  const presentingElement = document.getElementById(".ion-page");

  useIonViewWillEnter(() => {
    fetchCart();
  }, []);

  return (
    <RouteBackModal
      prevMatch={prevMatch}
      title="Dostawa"
      presentingElement={presentingElement}
      removeNestedOutlet={() => {}}
    >
      <IonContent className="ion-padding">
        <div style={{ marginTop: "var(--ion-safe-area-top)" }}>
          <Group>
            <Title>Koszyk</Title>
          </Group>
          {(carts || [])
            .sort((a, b) => a.createdAt - b.createdAt)
            .map(({ location, items }) => (
              <Section
                key={location.id}
                title={
                  <div
                    onClick={() => {
                      history.push(`/tabs/discovery/locations/${location.id}`);
                    }}
                  >
                    {location.name}
                  </div>
                }
                inset="4"
              >
                <LayoutGroup key={location.id}>
                  <MobileSafeAnimatePresence initial={false}>
                    <Stack gap={7}>
                      {items
                        .sort(
                          (a, b) => Number(a.createdAt) - Number(b.createdAt),
                        )
                        .map((item) => (
                          <motion.div
                            key={item.id}
                            layout
                            initial={{ opacity: 0, y: 50, scale: 0.3 }}
                            animate={{ opacity: 1, y: 0, scale: 1 }}
                            exit={{
                              opacity: 0,
                              scale: 0.5,
                              transition: { duration: 0.2 },
                            }}
                          >
                            <ShoppingCartItem
                              item={item}
                              locationId={location.id}
                            />
                          </motion.div>
                        ))}
                    </Stack>
                  </MobileSafeAnimatePresence>
                </LayoutGroup>
              </Section>
            ))}

          <Title order={4} className="text-right">
            Suma: <Price value={totalSum} />
          </Title>

          <Button
            fullWidth
            radius="lg"
            style={{
              fontWeight: 400,
            }}
            className="my-8"
            onClick={() => {
              history.push(`${match.url}/delivery`);
            }}
          >
            Dostawa i płatność
          </Button>
        </div>
      </IonContent>
      <ModalAsRoute
        prevMatch={match}
        routes={{
          [`${match.url}/delivery`]: CartDeliveryModalPage,
        }}
      />
    </RouteBackModal>
  );
};
