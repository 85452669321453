import { createRefreshAccessToken, isAccessTokenExpired } from "@zozia/oidc";
import * as React from "react";

import { logout } from "./utils/auth";
import { Session, getSnapshot, storeSession, subscribe } from "./utils/vault";

const refreshAccessToken = createRefreshAccessToken<Session>({
  clientID: "zozia-mobile-production",
});

export const useSession = () => {
  const snapshot = React.useSyncExternalStore(subscribe, getSnapshot);

  const getAccessToken = async () => {
    if (!snapshot) {
      return null;
    }

    const accessToken = snapshot?.accessToken;

    const { expired } = isAccessTokenExpired({ exp: snapshot.user.exp });
    if (expired) {
      try {
        const token = await refreshAccessToken({
          refreshToken: snapshot.refreshToken,
        });

        await storeSession(token);

        return token.accessToken;
      } catch (error) {
        console.log("Error refreshing token", JSON.stringify(error));
        await logout();
        return null;
      }
    } else {
      return accessToken;
    }
  };

  return {
    snapshot,
    getAccessToken,
  };
};
