import { useIonRouter } from "@ionic/react";
import { Money } from "@zozia/money";
import * as React from "react";

import {
  useAddCartItemMutation,
  useCalculateShoppingCartQuery,
  useCompleteCartProcessMutation,
  useDecreaseCartItemMutation,
  useIncreaseCartItemMutation,
  useRemoveCartItemMutation,
  useRemoveCartMutation,
  useSetCartDeliveryAddressMutation,
  useSetCartGroupDeliveryOptionMutation,
  useSetCartGroupDeliveryPackingOptionMutation,
  useSetCartGroupPaymentMethodMutation,
  useSetCartItemQuantityMutation,
  useShoppingCartsQuery,
} from "../graphql/graphql";
import { useSession } from "../session";
import { initialState, reducer } from "./Cart.reducer";
import { CartContext } from "./CartContext";
import { CompleteCartProcessArgs } from "./types";

type CartProviderProps = {
  children: React.ReactNode;
};

export const CartProvider = ({ children }: CartProviderProps) => {
  const [store, dispatch] = React.useReducer(reducer, initialState);
  const { snapshot } = useSession();
  const router = useIonRouter();

  const cart = store.cart;

  const { data: calculatedCart, refetch: recalculateShoppingCartQuery } =
    useCalculateShoppingCartQuery(
      {
        shoppingCartId: cart.id,
      },
      {
        enabled: !!snapshot && !!cart.id,
        select: (data) => data.calculateShoppingCart,
      },
    );

  const {
    data,
    refetch: refetchCarts,
    isFetching: isFetchingShoppingCarts,
  } = useShoppingCartsQuery(undefined, {
    enabled: !!snapshot,
    onSuccess: (data) => {
      if (data?.shoppingCarts) {
        dispatch({ type: "HYDRATE_CARTS", payload: data.shoppingCarts });
      }
    },
  });

  const refetch = async () => {
    await refetchCarts();
    if (cart.id) {
      await recalculateShoppingCartQuery();
    }
  };

  const { mutateAsync: increaseCartItemAsync } = useIncreaseCartItemMutation({
    onMutate: async ({ input }) => {
      // dispatch({ type: "OPTIMISTIC_INCREASE_ITEM", payload: input.cartItemId });
    },
    onSuccess: () => {
      refetch();
    },
  });
  const { mutate: decreaseCartItem } = useDecreaseCartItemMutation({
    onMutate: async ({ input }) => {
      // dispatch({ type: "OPTIMISTIC_DECREASE_ITEM", payload: input.cartItemId });
    },
    onSuccess: () => {
      refetch();
    },
  });
  const { mutateAsync: addItemAsync } = useAddCartItemMutation({
    onSuccess: (data) => {
      console.log(data.addCartItem.cartItemId);
      refetch();
    },
  });
  const { mutate: setCartItemQuantity } = useSetCartItemQuantityMutation({
    onSuccess: () => {
      refetch();
    },
  });
  const { mutateAsync: setCartGroupDeliveryOption } =
    useSetCartGroupDeliveryOptionMutation();
  const { mutateAsync: setDeliveryAddressAsync } =
    useSetCartDeliveryAddressMutation();
  const { mutateAsync: removeItemAsync } = useRemoveCartItemMutation();
  const { mutateAsync: setCartGroupDeliveryPackingOptionAsync } =
    useSetCartGroupDeliveryPackingOptionMutation();
  const { mutateAsync: completeProcessAsync } = useCompleteCartProcessMutation({
    onMutate: () => {
      router.push("/tabs/profile");
    },
    onSuccess: () => {
      refetchCarts();
    },
  });
  const { mutateAsync: removeCart } = useRemoveCartMutation();

  const {
    mutate: setCartGroupPaymentMethodMutateAsync,
    isLoading: isLoadingSetCartPaymentMethod,
  } = useSetCartGroupPaymentMethodMutation({
    onSuccess: () => {
      refetch();
    },
  });

  React.useEffect(() => {
    if (!snapshot) {
      dispatch({ type: "CLEAR_CART" });
    } else {
      refetch();
    }
  }, [snapshot]);

  const addItem = async ({
    locationId,
    offerId,
    quantity,
  }: {
    locationId: string;
    offerId: string;
    quantity: number;
  }) => {
    setCartItemQuantity({
      input: {
        offerId,
        locationId,
        quantity,
      },
    });
  };

  const increaseItem = async ({
    locationId,
    offerId,
  }: {
    locationId: string;
    offerId: string;
  }) => {
    await increaseCartItemAsync({
      input: {
        locationId,
        offerId,
      },
    });
  };

  const decreaseItem = (input) => {
    decreaseCartItem({
      input: input,
    });
  };

  const setDeliveryAddress = async ({
    cartId,
    deliveryAddressId,
    oneTimeDeliveryAddress,
  }: {
    deliveryAddressId: string;
    cartId: string;
    oneTimeDeliveryAddress: any;
  }) => {
    await setDeliveryAddressAsync({
      input: {
        deliveryAddressId,
        cartId,
        oneTimeDeliveryAddress,
      },
    });
    await refetch();
  };

  const setDeliveryOption = async ({
    cartGroupId,
    deliveryOptionId,
  }: {
    deliveryOptionId: string;
    cartGroupId: string;
  }) => {
    await setCartGroupDeliveryOption({
      input: {
        deliveryOptionId,
        cartGroupId,
      },
    });
    await refetch();
  };

  const setCartGroupPaymentMethod = ({
    cartGroupId,
    paymentMethodId,
  }: {
    paymentMethodId: string;
    cartGroupId: string;
  }) => {
    setCartGroupPaymentMethodMutateAsync({
      input: {
        paymentMethodId,
        cartGroupId,
      },
    });
  };

  const setDeliveryPackingOption = async ({
    cartGroupId,
    deliveryPackingOptionId,
  }: {
    deliveryPackingOptionId: string;
    cartGroupId: string;
  }) => {
    await setCartGroupDeliveryPackingOptionAsync({
      input: {
        deliveryPackingOptionId,
        cartGroupId,
      },
    });
    await refetch();
  };

  const flatCartItems = (cart && cart.items) || [];

  const isCartEmpty = flatCartItems.length === 0;

  React.useEffect(() => {
    if (isCartEmpty) {
      dispatch({ type: "CLOSE_CART_BUTTON" });
    } else {
      dispatch({ type: "OPEN_CART_BUTTON" });
    }
  }, [isCartEmpty]);

  const totalDeliverySum = Money.fromFractionlessAmount(
    cart?.deliveryOptionCopy?.deliveryPrice,
    "PLN",
  );

  const totalPackingSum = Money.fromFractionlessAmount(
    cart?.deliveryPackingOptionCopy?.packingPrice,
    "PLN",
  );

  const itemsSum = Money.reduce(
    ...flatCartItems.map((item) =>
      Money.fromFactionlessAndQuantity(item.price, item.quantity, "PLN"),
    ),
  );

  const totalSum = Money.reduce(itemsSum, totalDeliverySum, totalPackingSum);

  const getItemCount = (id: string) => {
    const count = flatCartItems.find((item) => item.offerId === id)?.quantity;
    return count;
  };

  const removeItem = async (cartItemId: string) => {
    await removeItemAsync({
      input: {
        cartItemId,
      },
    });
    await refetch();
  };

  const getCartItem = (offerId: string) => {
    const cartItems = flatCartItems.find((item) => item.offer.id === offerId);

    return cartItems;
  };

  const toggleCartButton = () => {
    dispatch({ type: "TOGGLE_CART_BUTTON" });
  };

  const openCartButton = () => {
    dispatch({ type: "OPEN_CART_BUTTON" });
  };

  const closeCartButton = () => {
    dispatch({ type: "CLOSE_CART_BUTTON" });
  };

  const completeProcess = async (
    input: CompleteCartProcessArgs,
    { onSuccess },
  ) => {
    await completeProcessAsync(
      {
        input,
      },
      {
        onSuccess: onSuccess ? onSuccess : () => {},
      },
    );
  };

  const isCartButtonVisible = !isCartEmpty && store.showCartButton;

  const c = Object.values(calculatedCart || {});

  const hasOnlyCashPaymentMethod =
    c.map((group) => group.cash).filter(Boolean).length === c.length;

  const hasOnlyOnlinePaymentMethod =
    c.map((group) => group.online).filter(Boolean).length === c.length;

  const isSamePaymentMethod =
    hasOnlyOnlinePaymentMethod || hasOnlyCashPaymentMethod;

  const isCartLoading =
    isFetchingShoppingCarts || isLoadingSetCartPaymentMethod;

  console.log({
    isCartLoading,
    isFetchingShoppingCarts,
    isLoadingSetCartPaymentMethod,
  });
  return (
    <CartContext.Provider
      value={{
        isCartLoading: isCartLoading,
        isCartButtonVisible,
        toggleCartButton,
        getCartItem,
        removeItem,
        getItemCount,
        totalSum,
        cart,
        addItem,
        increaseItem,
        setDeliveryAddress,
        decreaseItem,
        isEmpty: isCartEmpty,
        isFetchingShoppingCarts: isFetchingShoppingCarts,
        cartsCount: store.carts.length,
        itemsSum,
        removeCart: () => {
          removeCart(
            {
              cartId: cart.id as unknown as string,
            },
            {
              onSuccess: () => {
                refetchCarts();
              },
            },
          );
        },
        refreshCarts: refetch,
        carts: store.carts,
        fetchCart: refetch,
        setDeliveryOption,
        openCartButton,
        closeCartButton,
        setDeliveryPackingOption,
        totalPackingSum,
        totalDeliverySum,
        completeProcess,
        setCartGroupPaymentMethod,
        hasOnlyCashPaymentMethod,
        hasOnlyOnlinePaymentMethod,
        isSamePaymentMethod,
        updateLocation: (locationId: string) => {
          dispatch({ type: "UPDATE_LOCATION", payload: locationId });
        },
        dispatch,
        state: store,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
