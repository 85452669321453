import { Card, Group, Stack, Text } from "@mantine/core";
import { Img, Price, cropImageTo } from "@zozia/ui";
import * as React from "react";

import { useCart } from "../CartContext";
import { ChangeQuantity } from "./ChangeQuantity";

const cropImageToDesiredSize = cropImageTo(50, 50);

type ShoppingCartItemProps = {
  item: any;
  locationId: string;
};

export const ShoppingCartItem = ({
  item,
  locationId,
}: ShoppingCartItemProps) => {
  const { addItem, removeItem, decreaseItem } = useCart();

  return (
    <Card bg="#FFFFFF" radius="lg" padding="xs">
      <Group wrap="nowrap" align="start">
        {item?.offer?.product?.primaryImage?.url ? (
          <Img
            className="h-[50px] w-[50px] rounded-xl"
            src={cropImageToDesiredSize(item.offer.product.primaryImage.url)}
          />
        ) : null}
        <Stack className="-mt-1 w-full" gap={4}>
          <Text size="xs" fw="600">
            {item.offer.product.name}
          </Text>
          <Text size="xs">
            <Price value={item.price} />
          </Text>
          <Group wrap="nowrap" grow>
            <Text
              size="xs"
              td="underline"
              c="blue"
              onClick={() => removeItem(item.id)}
            >
              Usuń
            </Text>
            <ChangeQuantity
              initialValue={item.quantity}
              onIncrease={(quantity) => {
                addItem({
                  offerId: item.offer.id,
                  locationId,
                  quantity,
                });
              }}
              onDecrease={() => {
                decreaseItem({
                  offerId: item.offer.id,
                  locationId,
                });
              }}
            />
          </Group>
        </Stack>
      </Group>
    </Card>
  );
};
