import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { ActionIcon, Group } from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import { motion } from "framer-motion";
import * as React from "react";

import { cn } from "../../../../lib/mergeTailwindClasses";
import { DecreaseQuantity } from "./DecreaseQuantity";

type ProductQuantityActions = {
  initialItemQuantity: number;
  className?: string;
  onIncrease: (quantity: number) => void;
  onDecrease: (quantity: number) => void;
};

export const ProductQuantityActions = ({
  initialItemQuantity,
  className,
  onIncrease,
  onDecrease,
}: ProductQuantityActions) => {
  const [active, setActive] = React.useState(false);
  const ref = useClickOutside(() => setActive(false));

  return (
    <div className={cn("relative", className)} ref={ref}>
      <motion.div
        className="absolute overflow-hidden border border-solid border-gray-300 whitespace-nowrap bg-white rounded-md"
        onClick={async (event) => {
          event.preventDefault();

          await Haptics.impact({
            style: ImpactStyle.Light,
          });
          setActive((prev) => !prev);
        }}
        initial={{
          borderRadius: "4px",
          width: 65,
        }}
        transition={{
          duration: 0.2,
        }}
        animate={{
          width: active ? 170 : 65,
          left: active ? -120 : 0,
          borderRadius: active ? "20px" : "4px",
        }}
        style={{
          boxShadow: active ? "rgba(0, 0, 0, 0.2) 0px 0px 20px 0" : "none",
        }}
        layout
      >
        <Group
          justify="start"
          align="center"
          className="p-1"
          grow
          wrap="nowrap"
        >
          {active ? (
            <DecreaseQuantity
              quantity={initialItemQuantity}
              onDecrease={async (event) => {
                event.stopPropagation();

                const nextQuantity = initialItemQuantity - 1;

                Haptics.impact({
                  style: ImpactStyle.Light,
                });
                onDecrease(nextQuantity);
              }}
            />
          ) : null}
          <div
            className="table text-center"
            onClick={(e) => (active ? e.stopPropagation() : null)}
          >
            {initialItemQuantity} szt.
          </div>
          {active ? (
            <ActionIcon
              size="xs"
              variant="transparent"
              c="black"
              onClick={async (event) => {
                event.stopPropagation();
                const nextQuantity = initialItemQuantity + 1;

                Haptics.impact({
                  style: ImpactStyle.Light,
                });
                onIncrease(nextQuantity);
              }}
            >
              <IconPlus />
            </ActionIcon>
          ) : null}
        </Group>
      </motion.div>
    </div>
  );
};
