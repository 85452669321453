import {
  ApplePayEventsEnum,
  PaymentSheetEventsEnum,
} from "@capacitor-community/stripe";
import { useCapacitorStripe } from "@capacitor-community/stripe/dist/esm/react/provider";
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonSpinner,
  useIonViewWillEnter,
} from "@ionic/react";
import {
  Badge,
  Button,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconInfoCircle, IconMapPin, IconUser } from "@tabler/icons-react";
import { tryToGetMostAccurateAddress } from "@zozia/address";
import { Price } from "@zozia/ui";
import { motion } from "framer-motion";
import * as React from "react";
import { type match, useHistory, useRouteMatch } from "react-router-dom";

import { MobileSafeAnimatePresence } from "../../../components/MobileSafeAnimatePresence";
import { RouteBackModal } from "../../../components/RouteBackModal";
import { useCreatePaymentIntentMutation } from "../../graphql/graphql";
import { Section, SummarySection } from "../../session/components/Section";
import { useUserAddress } from "../../session/hooks/useUserDeliveryAddress";
import { useUser } from "../../session/useUser";
import { useCart } from "../CartContext";
import { LocationDeliveryOptionSelector } from "../components/LocationDeliveryOptionSelector";
import { LocationDeliveryPackingSelector } from "../components/LocationDeliveryPackingSelector";
import { LocationPaymentMethodSelector } from "../components/LocationPaymentMethodSelector";
import { ShoppingCartItem } from "../components/ShoppingCartItem";
import css from "./CartDeliveryModalPage.module.css";

type CartDeliveryModalPageProps = {
  prevMatch: match;
  opened: boolean;
  open: () => void;
  removeNestedOutlet: () => void;
};
export const CartDeliveryModalPage = ({
  prevMatch,
  removeNestedOutlet,
}: CartDeliveryModalPageProps) => {
  const { stripe, isApplePayAvailable } = useCapacitorStripe();
  const [isPaymentInProgress, setIsPaymentInProgress] = React.useState(false);
  const { mutateAsync: createPaymentIntentAsync } =
    useCreatePaymentIntentMutation();
  const {
    setDeliveryOption,
    setDeliveryPackingOption,
    fetchCart,
    totalSum,
    totalDeliverySum,
    totalPackingSum,
    cart,
    setCartGroupPaymentMethod,
    isSamePaymentMethod,
    hasOnlyCashPaymentMethod,
    completeProcess,
  } = useCart();
  const [user] = useUser();
  const history = useHistory();
  const modal = React.useRef<HTMLIonModalElement>(null);
  const match = useRouteMatch();

  const { address, isLoading: isDeliveryAddressLoading } =
    useUserAddress("DeliveryAddress");

  useIonViewWillEnter(() => {
    fetchCart();
  }, []);

  const { address: myDeliveryAccess, isLoading } =
    useUserAddress("DeliveryAddress");

  const payOnline = async () => {
    try {
      setIsPaymentInProgress(true);
      const { createPaymentIntent } = await createPaymentIntentAsync({
        input: {
          shoppingCartId: cart.id as unknown as string,
        },
      });

      if (!isApplePayAvailable) {
        await stripe.createPaymentSheet({
          paymentIntentClientSecret: createPaymentIntent.paymentIntent,
          customerEphemeralKeySecret: createPaymentIntent.ephemeralKey,
          customerId: createPaymentIntent.customer,
          merchantDisplayName: "Papuga Zoo",
          returnURL: "com.zozia.app://tabs/discovery",
        });

        const { paymentResult } = await stripe.presentPaymentSheet();

        if (paymentResult === PaymentSheetEventsEnum.Completed) {
          completeProcess({
            cartId: cart.id as unknown as string,
            paymentMethodType: "Online",
            paymentProcessCompleted: true,
            deliveryAddressId: address.id,
          });
        }
      }

      if (isApplePayAvailable) {
        await stripe.createApplePay({
          merchantIdentifier: "merchant.com.zozia.app",
          paymentIntentClientSecret: createPaymentIntent.paymentIntent,
          paymentSummaryItems: [
            {
              label: "Zozia Marketplace",
              amount: totalSum,
            },
          ],
          countryCode: "PL",
          currency: "pln",
        });
        const { paymentResult } = await stripe.presentApplePay();

        if (paymentResult === ApplePayEventsEnum.Completed) {
          completeProcess({
            cartId: cart.id as unknown as string,
            paymentMethodType: "Online",
            paymentProcessCompleted: true,
            deliveryAddressId: address.id,
          });
        }
      }
    } finally {
      setIsPaymentInProgress(false);
    }
  };

  return (
    <RouteBackModal
      prevMatch={prevMatch}
      ref={modal}
      removeNestedOutlet={removeNestedOutlet}
      title="Dostawa"
    >
      <IonContent className="ion-padding">
        {isLoading ? (
          <LoadingOverlay />
        ) : (
          <>
            <Section title="Dane odbiorcy"></Section>
            <IonList className={css.itemWithNoPadding}>
              {isDeliveryAddressLoading ? (
                <IonSpinner />
              ) : (
                <>
                  <IonItem lines="none">
                    <IconUser slot="start" />
                    <IonLabel>
                      {user?.name}
                      <Text>
                        {user?.phoneNumber
                          ? user.phoneNumber
                          : "Brak numeru telefonu"}
                      </Text>
                    </IonLabel>
                    {user?.phoneNumber ? null : (
                      <Button
                        variant="subtle"
                        onClick={() => {
                          history.push(
                            `/tabs/settings/account/setupPhoneNumber?redirect=${match.url}`,
                          );
                        }}
                      >
                        Dodaj
                      </Button>
                    )}
                  </IonItem>
                  <IonItem lines="none">
                    <IconMapPin slot="start" />
                    <IonLabel>
                      {tryToGetMostAccurateAddress(myDeliveryAccess).primary}
                      <Text>
                        {
                          tryToGetMostAccurateAddress(myDeliveryAccess)
                            .secondary
                        }
                      </Text>
                    </IonLabel>
                    <Button
                      variant="subtle"
                      onClick={() => {
                        history.push(
                          `/tabs/profile/deliveryAddress?redirect=${match.url}`,
                        );
                      }}
                    >
                      Zmień
                    </Button>
                  </IonItem>
                  <IonItem lines="none">
                    <IconInfoCircle slot="start" />
                    <IonLabel>
                      <h3>Dodatkowe informacje</h3>
                      <p>{address?.additionalInformation}</p>
                    </IonLabel>
                    <Button
                      variant="subtle"
                      onClick={() => {
                        history.push(
                          `/tabs/profile/deliveryAddress/additionalInfo?redirect=${match.url}&state=useSavedAddress`,
                        );
                      }}
                    >
                      {address?.additionalInformation ? "Zmień" : "Dodaj"}
                    </Button>
                  </IonItem>
                </>
              )}
            </IonList>
            {cart?.groups?.map(
              ({
                id,
                location,
                items,
                deliveryOption,
                deliveryPackingOption,
                paymentMethodId,
              }) => (
                <Section
                  key={location.id}
                  title={
                    <div
                      onClick={() => {
                        history.push(
                          `/tabs/discovery/locations/${location.id}`,
                        );
                      }}
                    >
                      {location.name}
                    </div>
                  }
                  inset="4"
                >
                  <MobileSafeAnimatePresence initial={false}>
                    <Stack gap={7}>
                      {items.map((item) => (
                        <motion.div
                          key={item.id}
                          layout
                          initial={{ opacity: 0, y: 50, scale: 0.3 }}
                          animate={{ opacity: 1, y: 0, scale: 1 }}
                          exit={{
                            opacity: 0,
                            scale: 0.5,
                            transition: { duration: 0.2 },
                          }}
                        >
                          <ShoppingCartItem
                            key={item.id}
                            item={item}
                            locationId={location.id}
                          />
                        </motion.div>
                      ))}
                    </Stack>
                  </MobileSafeAnimatePresence>
                  <LocationDeliveryOptionSelector
                    key={deliveryOption?.id}
                    locationId={location.id}
                    value={deliveryOption?.id}
                    onChange={({ deliveryOptionId }) => {
                      setDeliveryOption({
                        cartGroupId: id,
                        deliveryOptionId,
                      });
                    }}
                  />
                  <LocationDeliveryPackingSelector
                    key={deliveryPackingOption?.id}
                    locationId={location.id}
                    value={deliveryPackingOption?.id}
                    onChange={({ deliveryPackingOptionId }) => {
                      setDeliveryPackingOption({
                        cartGroupId: id,
                        deliveryPackingOptionId,
                      });
                    }}
                  />
                  <LocationPaymentMethodSelector
                    key={paymentMethodId}
                    locationId={location.id}
                    value={paymentMethodId}
                    onChange={({ paymentMethodId }) => {
                      setCartGroupPaymentMethod({
                        cartGroupId: id,
                        paymentMethodId,
                      });
                    }}
                  />
                </Section>
              ),
            )}
          </>
        )}
        <SummarySection
          title="Podsumowanie"
          inset="clear"
          className={css.summaryWrapper}
        >
          <IonList className={css.summary} lines="none">
            <IonItem lines="none">
              <IonLabel>Koszt dostawy</IonLabel>
              <Price value={totalDeliverySum} />
            </IonItem>
            <IonItem lines="none">
              <IonLabel>Koszt pakowania</IonLabel>
              <Price value={totalPackingSum} />
            </IonItem>
            {isSamePaymentMethod ? null : (
              <>
                <IonItem lines="none">
                  <IonLabel>Płatność na miejscu</IonLabel>
                  <Price value={(totalSum * 100 - totalSum) / 100} />
                </IonItem>
                <IonItem lines="none">
                  <IonLabel>Płatność online</IonLabel>
                  <Price value={totalSum / 100} />
                </IonItem>
              </>
            )}
            <IonItem lines="none">
              <IonLabel>
                <Title order={1}>Razem</Title>
              </IonLabel>
              <Price value={totalSum} />
            </IonItem>
          </IonList>
          <pre>{JSON.stringify({ isApplePayAvailable }, null, 2)}</pre>
          {hasOnlyCashPaymentMethod ? (
            <Button
              fullWidth
              radius="lg"
              style={{
                fontWeight: 400,
              }}
              className="my-8"
              onClick={async () => {
                completeProcess({
                  cartId: cart.id as unknown as string,
                  paymentMethodType: "Cash",
                  paymentProcessCompleted: true,
                  deliveryAddressId: address.id,
                });
              }}
            >
              Zamów z obowiązkiem zapłaty
            </Button>
          ) : (
            <Button
              loading={isPaymentInProgress}
              fullWidth
              radius="lg"
              style={{
                fontWeight: 400,
              }}
              className="my-8"
              onClick={payOnline}
            >
              Zamów i zapłać
              <Badge className="ml-4 bg-blue-800">
                <Price value={totalSum} />
              </Badge>
            </Button>
          )}
        </SummarySection>
      </IonContent>
    </RouteBackModal>
  );
};
