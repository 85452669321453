import {
  BrowserVault,
  DeviceSecurityType,
  Vault,
  VaultType,
} from "@ionic-enterprise/identity-vault";
import * as Sentry from "@sentry/capacitor";

import { createVault } from "./vault-factory";

export type Session = {
  accessToken: string;
  refreshExpiresIn: string;
  refreshToken: string;
  expiresIn: string;
  user: {
    id: string;
    sub: string;
    exp: number;
    iat: number;
    givenName?: string;
    familyName?: string;
    email: string;
  };
};

const vault: Vault | BrowserVault = createVault();
let session: Session | null = null;
let listeners: any[] = [];

export const initializeVault = async (): Promise<void> => {
  await vault.initialize({
    key: "com.zozia.app",
    type: VaultType.SecureStorage,
    deviceSecurityType: DeviceSecurityType.None,
  });
  await getSession();
};

export const getSnapshot = (): Session | null => {
  return session;
};

export const subscribe = (listener: any) => {
  listeners = [...listeners, listener];
  return () => {
    listeners = listeners.filter((l) => l !== listener);
  };
};

export const emitChange = () => {
  for (let listener of listeners) {
    listener();
  }
};

export const storeSession = async (newSession: Session): Promise<void> => {
  try {
    await vault.setValue("session", newSession);
    session = newSession;
    emitChange();
  } catch (error) {
    Sentry.setContext("storeSession", newSession ?? {});
    Sentry.captureException(error);
    await vault.clear();
  }
};

export const getSession = async (): Promise<void> => {
  if (session === null) {
    if (await vault.isEmpty()) {
      session = null;
    } else {
      try {
        const newSession = await vault.getValue<Session>("session");
        session = newSession;
      } catch (error) {
        await vault.clear();
      }
    }
  }
  emitChange();
};

export const clearSession = async (): Promise<void> => {
  await vault.clear();
  session = null;
  emitChange();
};
